import * as API from "./API";

import store from "../store";

export default {

    getAgeCategoriesById(id) {
        return API.apiClient.get(`/api/Dosages/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createAgeCategories(AgeCategories) {
        return API.apiClient.post(`/api/Dosages`, AgeCategories, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getAgeCategoriess(type) {
        return API.apiClient.get(`/api/Dosages?type=${type}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteAgeCategories(id) {
        return API.apiClient.delete(`/api/Dosages/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateAgeCategories(id, AgeCategories) {
        return API.apiClient.put(`/api/Dosages/${id}`, AgeCategories, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

};