<template>
  <div class="">
    <div class="">
      <h1 class="text-2xl font-semibold text-slate-900 dark:text-white">
        التقارير
      </h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div class="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-3">
        <div
          class="relative flex flex-col bg-clip-border rounded-xl bg-white dark:bg-slate-700 dark:text-gray-200 text-gray-700 shadow-md"
        >
          <div class="p-4 text-right">
            <p
              class="block antialiased text-base leading-normal font-normal text-blue-gray-600"
            >
              عدد المسجلين بالمركز
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900 dark:text-white"
            >
              {{ dashboard_data.total_of_citizen }}
            </h4>
          </div>

          <div
            class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute left-0 -mt-4 grid h-16 w-16 place-items-center"
          >
            <svg
              class="w-6 h-6 text-white fill-current"
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 50 50"
              viewBox="0 0 50 50"
            >
              <path
                d="M24.3141,5.9217c-1.1045,0-2,0.8955-2,2v1.0835c0,3.0571-2.331,5.5801-5.3088,5.8854h-6.6319c-2.9783-0.3052-5.3093-2.8283-5.3093-5.8854V7.9217c0-2.6419-4-2.6449-4,0v1.0835c0,4.4979,3.0131,8.3001,7.1251,9.5096v11.2928c-0.0042,0.0524-0.0157,0.1027-0.0157,0.1561v16c0,1.1045,0.8955,2,2,2s2-0.8955,2-2V30.9219h2.9585v15.0419c0,1.1045,0.8955,2,2,2s2-0.8955,2-2V30.9219h0.0573v-12.407c4.1119-1.2095,7.1249-5.0116,7.1249-9.5096V7.9217C26.3141,6.8173,25.4186,5.9217,24.3141,5.9217z"
              />
              <path
                d="M13.6893 12.9219c2.2091 0 4-1.7909 4-4 0-2.2092-1.7909-4-4-4C8.4061 4.9219 8.3991 12.9219 13.6893 12.9219zM6.06 8.736v.27M4 17.366H2.26v-.5C1.8611 16.8992 1.3929 16.8715 1 16.736v4.72h6.19v-2.22C6.01 18.796 4.94 18.166 4 17.366zM47.52 17.366v-1.33h-1.18v-3.24H45.1v3.24h-1.17v-1.97h-2.51v1.97H40.3v-9.69h-.62v-2.81h-.96v-1.5h-2.7v1.5h-.96v2.81h-.61v11.02h-.92v-7.93H27.29c-.06 1.73-.54 3.39-1.37 4.85.22.16.36.43.36.75 0 .52-.4.94-.89.94-.18 0-.35-.05-.49-.15-1.22 1.51-2.83 2.71-4.71 3.41v2.22H49v-4.09H47.52zM28.2 15.976c-.51 0-.92-.42-.92-.94 0-.53.41-.94.92-.94.49 0 .89.41.89.94C29.09 15.556 28.69 15.976 28.2 15.976zM28.2 12.956c-.51 0-.92-.42-.92-.93 0-.54.41-.96.92-.96.49 0 .89.42.89.96C29.09 12.536 28.69 12.956 28.2 12.956zM30.98 15.976c-.5 0-.89-.42-.89-.94 0-.53.39-.94.89-.94s.92.41.92.94C31.9 15.556 31.48 15.976 30.98 15.976zM30.98 12.956c-.5 0-.89-.42-.89-.93 0-.54.39-.96.89-.96s.92.42.92.96C31.9 12.536 31.48 12.956 30.98 12.956zM36.34 16.576h-1.06v-8.12h1.06V16.576zM37.89 16.576h-1.03v-8.12h1.03V16.576zM39.46 16.576h-1.05v-8.12h1.05V16.576z"
              />
              <rect width="2.259" height="3.54" x="30.459" y="4.81" />
              <path d="M1.355,16.756" />
            </svg>
          </div>
        </div>

        
      </div>

      <div class="">
        <div class="">
          <p class="text-xl font-semibold text-slate-900 dark:text-white">
            نوع التقرير
          </p>
          <div class="mt-2 flex justify-between items-center">
            <button
              @click="report_type_fun(1)"
              :class="
                report_type == 1
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/2 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              تقرير اللقاحات
            </button>

            <button
              @click="report_type_fun(2)"
              :class="
                report_type == 2
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/2 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              تقرير اللقاحات المفصل
            </button>
          </div>
        </div>

        <div class="mt-6">
          <p class="text-xl font-semibold text-slate-900 dark:text-white">
            الفترة
          </p>
          <div class="mt-2 flex justify-between items-center">
            <button
              @click="period_fun(2);
                open_custom_date = false;"
              :class="
                period == 2
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              اليوم
            </button>

            <button
              @click="period_fun(3);
                open_custom_date = false;"
              :class="
                period == 3
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              الشهر
            </button>

            <button
              @click="period_fun(4);
                open_custom_date = false;"
              :class="
                period == 4
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              السنة
            </button>

            <button
              @click="period_fun(1);
                open_custom_date = false;"
              :class="
                period == 1
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              الكل
            </button>
            <button
              @click="
                period_fun(5);
                open_custom_date = true;
              "
              :class="
                period == 5
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              مخصص
            </button>
          </div>

          <div
            v-if="open_custom_date"
            class="mt-2 flex justify-between items-center space-x-reverse space-x-6"
          >
            <div class="input w-1/3 flex items-center">
              <label
                for="filter_date_from"
                class="w-12 text-sm font-medium"
              >
                من :
              </label>
              <input
                type="date"
                id="filter_date_from"
                placeholder="أكتب تاريخ الميلاد."
                v-model="filter_date_from"
                class="mt-1 w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input w-1/3 flex items-center">
              <label
                for="filter_date_to"
                class="w-12 text-sm font-medium"
              >
                الي :
              </label>
              <input
                type="date"
                id="filter_date_to"
                placeholder="أكتب تاريخ الميلاد."
                v-model="filter_date_to"
                class="mt-1 w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <!-- <div class="w-1/3 cursor-pointer text-white">
              <button
                @click="period_fun_most_consumed_muncipals(5)"
                type="button"
                class="border border-transparent rounded-md shadow-sm w-full py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                بحث
              </button>
            </div> -->
          </div>
        </div>





        <div class="mt-6">
          <p class="text-xl font-semibold text-slate-900 dark:text-white">
            تصنيف اللقاح
          </p>
          <div class="mt-2 flex justify-between items-center">
            <button
              @click="vaccine_type_fun(1)"
              :class="
                vaccine_type == 1
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              الإجباري
            </button>

            <button
              @click="vaccine_type_fun(2)"
              :class="
                vaccine_type == 2
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              كبار
            </button>

            <button
              @click="vaccine_type_fun(3)"
              :class="
                vaccine_type == 3
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              مسافرين
            </button>

            <button
              @click="vaccine_type_fun(4)"
              :class="
                vaccine_type == 4
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              حملات
            </button>
            <button
              @click="
                vaccine_type_fun(0)"
              :class="
                vaccine_type == 0
                  ? 'bg-green-600 hover:bg-green-700'
                  : 'bg-gray-600 hover:bg-gray-700'
              "
              class="w-1/4 text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none"
            >
              الكل
            </button>
          </div>

        </div>







        <div class="mt-10">

          <!--<div class="w-full bg-blue-400 text-center text-3xl text-white py-4">
            قيد تطوير التقارير
          </div>-->

             <router-link v-if="report_type == 1" title="عرض" 
                         :to="{ name: 'printPeriodicReportByVaccinationCenters', params: { period: period , id:vaccination_center_id, from:filter_date_from ,to:filter_date_to, vaccine_type:vaccine_type  },}" 
                         class="w-full block text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none bg-blue-600 hover:bg-blue-700" 
                        >
              عرض
            </router-link>



            <router-link v-if="report_type == 2" title="عرض" 
                         :to="{ name: 'printCitizenVaccinationReportByVaccinationCenters', params: { period: period , id:vaccination_center_id, from:filter_date_from ,to:filter_date_to, vaccine_type:vaccine_type  },}" 
                         class="w-full block text-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none bg-blue-600 hover:bg-blue-700" 
                        >
              عرض
            </router-link> 




            


        </div>
      </div>

      <div class="w-full mt-12">
        <BarChart :labels="labels" :datasets="datasets" />
      </div>
      
    </div>
  </div>
</template>

<script>
import Dashboard from "@/services/DashboardService";
// import Report from "@/services/ReportService";

import BarChart from "@/components/ChartJS/Bar.vue";

export default {
  components: {
    BarChart,
  },

  data() {
    return {

      filter_date_from:'',
      filter_date_to:'',
      

      open_custom_date: false,
      dashboard_data: {},

      labels: [],

      datasets: [
        {
          label: " ",
          backgroundColor: "#f87979",
          data: [],
        },
      ],

      most_consumeds_vaccine: [],

      vaccination_center_id: this.$store.state.auth.role.guid,

      period: 2,
      vaccine_type: 1,
      report_type: 1,
    };
  },

  created() {
    this.getDashboard();


    var date = new Date();

    var month = date.getMonth() + 1;
    var day = date.getDate();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;


    this.filter_date_from =
        date.getFullYear() + "-" + month + "-" + day;
      this.filter_date_to =
        date.getFullYear() + "-" + month + "-" + day;


  },
  methods: {
    period_fun(p) {
      this.period = p;
    },


    vaccine_type_fun(r) {
      this.vaccine_type = r;
    },


    report_type_fun(r) {
      this.report_type = r;
    },


    




   




    getDashboard() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      Dashboard.getDashboardForVaccinationCenters(this.vaccination_center_id)
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.dashboard_data = res.data;

            this.most_consumeds_vaccine =
              this.dashboard_data.most_consumeds_Vaccine;

            for (
              let index = 0;
              index < this.most_consumeds_vaccine.length;
              index++
            ) {
              const element = this.most_consumeds_vaccine[index];

              this.labels.push(element.name_en);
              this.datasets[0].data.push(element.total_obtained_Vaccines);
            }
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style></style>
