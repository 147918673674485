import * as API from "./API";

import store from "../store";

export default {

    getCitizensForSearch(national_number, first_name, middle_name, last_name, mother_name, family_paper_number, registration_number, brochure_number, passport_number, phone_number, page_number, page_size, citizen_number, nationalityid) {
        return API.apiClient.get(`/api/citizen/all?national_number=${national_number}&first_name=${first_name}&middle_name=${middle_name}&last_name=${last_name}&mother_name=${mother_name}&family_paper_number=${family_paper_number}&registration_number=${registration_number}&brochure_number=${brochure_number}&passport_number=${passport_number}&phone_number=${phone_number}&page_number=${page_number}&page_size=${page_size}&citizen_number=${citizen_number}&nationalityid=${nationalityid}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    transferRequests(citizen) {
        return API.apiClient.post(`/api/citizen_transfer/Add`, citizen, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    getCertificates(id) {
        return API.apiClient.get(`/api/Obtained_Vaccinese/health_certificate?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    getCitizenFamily(registration_number, id) {
        return API.apiClient.get(`/api/Citizen/GetFamilyCitizen?registration_number=${registration_number}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getCitizensWithoutPagination() {
        return API.apiClient.get(`/api/Citizen/GetAll`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getCitizensByFilterForVaccinationCenter(national_number, first_name, middle_name, last_name, mother_name, family_paper_number, registration_number, brochure_number, passport_number, phone_number, id, page_number, page_size, citizen_number, nationalityid, muncipal_id) {
        return API.apiClient.get(`/api/Citizen/get_all_citizen_for_health_center?national_number=${national_number}&first_name=${first_name}&middle_name=${middle_name}&last_name=${last_name}&mother_name=${mother_name}&family_paper_number=${family_paper_number}&registration_number=${registration_number}&brochure_number=${brochure_number}&passport_number=${passport_number}&phone_number=${phone_number}&id=${id}&page_number=${page_number}&page_size=${page_size}&citizen_number=${citizen_number}&nationalityid=${nationalityid}&muncipal_id=${muncipal_id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getCitizensNotBelong(national_number, first_name, middle_name, last_name, mother_name, family_paper_number, registration_number, brochure_number, passport_number, phone_number, id, page_number, page_size, citizen_number, nationalityid) {
        return API.apiClient.get(`/api/Citizen/get_all_citizen_for_health_center_not_belong?national_number=${national_number}&first_name=${first_name}&middle_name=${middle_name}&last_name=${last_name}&mother_name=${mother_name}&family_paper_number=${family_paper_number}&registration_number=${registration_number}&brochure_number=${brochure_number}&passport_number=${passport_number}&phone_number=${phone_number}&id=${id}&page_number=${page_number}&page_size=${page_size}&citizen_number=${citizen_number}&nationalityid=${nationalityid} `, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getCitizens(page_number, page_size, ) {
        return API.apiClient.get(`/api/Citizen/GetAllCitizenPage?page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getCitizensByMunicipal(page_number, page_size, municipal_id) {
        return API.apiClient.get(`/api/Citizen/get_all_citizen_By_muncipal_iD?page_number=${page_number}&page_size=${page_size}&municipal_id=${municipal_id} `, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getCitizensByVaccinationCenter(page_number, page_size, helthcenterId) {
        return API.apiClient.get(`/api/Citizen/GetAllCitizen?page_number=${page_number}&page_size=${page_size}&helthcenterId=${helthcenterId} `, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getCitizen(id) {
        return API.apiClient.get(`/api/Citizen/Citizen?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createCitizen(citizen) {
        return API.apiClient.post(`/api/Citizen/Citizen`, citizen, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createCitizenForVaccinationCenter(citizen) {
        return API.apiClient.post(`/api/Citizen/add_citizen_from_helth_center`, citizen, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteCitizen(id) {
        return API.apiClient.delete(`/api/Citizen/Citizen?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateCitizen(citizen) {
        return API.apiClient.put(`/api/Citizen/Citizen`, citizen, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



};