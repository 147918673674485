import * as API from "./API";

import store from "../store";

export default {
    // getMedicalSuppliesWithoutPagination(is_approved, id) {
    //     return API.apiClient.get(`/api/Order/get_orders_for_health_center?is_approved=${is_approved}&id=${id}`);
    // },

    getMedicalSupplies(page_number, page_size, is_approved, id) {
        return API.apiClient.get(`/api/Order/get_order_for_muncipal?page_number=${page_number}&page_size=${page_size}&is_approved=${is_approved}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    // getMedicalSuppliesById(id) {
    //     return API.apiClient.get(`/api/Order/Order?id=${id}`);
    // },

    createMedicalSupplies(order) {
        return API.apiClient.post(`/api/Order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    orderApproval(id) {
        return API.apiClient.get(`/api/Order/change_state_of_order_from_muncipla?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    cheekMedicalSuppliesStorages(vaccineId) {
        const id = "b6d0f91e-868f-4cee-9d8f-df13d5ce5840"
        return API.apiClient.get(`/api/Vaccination_Administration_municipality_requests/Cheek_Medical_supplies_Storages/${id}`,
            { params: { vaccine_id: vaccineId } },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${store.state.auth.accessToken}`
                }
            })
    },

    blockQuantityFromMedicalSuppliesStoreges(body, id) {
        return API.apiClient.post(`/api/Vaccination_Administration_municipality_requests/Block_quantity_from_medical_supplies_storeges/${id}`, body, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    approveOrder(id) {
        return API.apiClient.put(`/api/Vaccination_Administration_municipality_requests/change_order_state/${id}?order_state=2`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
                }
            });
    },

    acknowledgDelivery(id) {
        return API.apiClient.put(`/api/Vaccination_Administration_municipality_requests/change_order_state/${id}?order_state=4`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
                }
            });
    },

    rejectOrder(id, reson) {
        return API.apiClient.put(`/api/Vaccination_Administration_municipality_requests/rejection/${id}`, {
            reson: reson
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    }

};