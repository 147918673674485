<template>
  <div
    class="modal fixed top-0 left-0 flex justify-center items-center mx-auto w-full h-full z-50 overflow-x-hidden"
    :class="
      showModal
        ? 'modalVisible pointer-events-auto opacity-100'
        : 'pointer-events-none opacity-0 '
    "
  >
    <button @click="cancelModal">
      <CloseIcon
        class="close"
        :class="showModal ? 'closeAfter' : ''"
      />
    </button>
    <form
      ref="rejectModal"
      class="modal-wrap flex flex-col items-center max-h-formHeight bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 p-8 border dark:border-slate-800 sm:rounded-md screen:overflow-y-auto"
      :class="showModal ? 'modal-wrapVisable' : ''"
    >
      <div
        class="input w-full"
        :class="{ invalid: $v.reason.$error }"
      >
        <label
          for="vaccineDeliveryDocument_recipient"
          class="text-gray-800 font-medium text-lg bg-gray-100 p-2 rounded"
        >
          سبب الرفض
        </label>
        <textarea
          id="vaccineDeliveryDocument_recipient"
          v-model="reason"
          rows="4"
          type="text"
          placeholder="أكتب سبب الرفض ."
          class="mt-6 w-full border border-gray-300 p-2 rounded-lg outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-100"
          @blur="$v.reason.$touch()"
        />
      </div>

      <div
        class="text-white w-full md:w-auto flex md:justify-end gap-4 mt-8 self-end"
      >
        <div class="inline w-full md:w-auto">
          <button
            class="btn btn-cancel cursor-pointer"
            type="button"
            @click="cancelModal"
          >
            إلغاء
          </button>
        </div>
        <div
          class="inline w-full md:w-auto"
          :class="$v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer'"
        >
          <button
            :disabled="$v.$invalid"
            class="btn"
            :class="
              $v.$invalid
                ? 'bg-gray-600'
                : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            "
            type="button"
            @click="rejectOrder()"
          >
            رفض الطلب
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import MedicalSuppliesOrderService from "@/services/MedicalSuppliesOrdersService";
import CloseIcon from "./icons/CloseIcon.vue";

export default {
  components: {
    CloseIcon,
  },

  props: {
    order: {
      default: null,
      type: Object,
    },
    showModal: {
      default: null,
      type: Boolean,
    },
  },

  data() {
    return {
      reason: "",
      firstTime: true,
    };
  },

  validations: {
    reason: {
      required,
    },
  },

  watch: {
    showModal: {
      handler(showModal) {
        if (showModal) {
          this.firstTime = true;
          window.addEventListener("click", this.closeDialogOutside);
        }
      },
    },

    order: {
      handler(order) {
        if (order) { 
          this.reason = ""
        }
      }
    }
  },

  methods: {
    rejectOrder() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MedicalSuppliesOrderService.rejectOrder(this.order.id, this.reason)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
          }, 10);
          this.$emit("cancel-reject-modal", this.order.id);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    closeDialogOutside(event) {
      if (this.firstTime) {
        this.firstTime = false;
        return;
      }

      if (!this.$refs.rejectModal.contains(event.target)) {
        this.cancelModal();
      }
    },

    cancelModal() {
      this.$emit("cancel-reject-modal");
      window.removeEventListener("click", this.closeDialogOutside);
    },
  },
};
</script>

<style scoped>
.btn {
  font-size: 15px;
  font-weight: 500;
  line-height: 2;
  width: 140px;
  height: 40px;
  transition: all 200ms linear;
  border-radius: 4px;
  letter-spacing: 1px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  -ms-flex-item-align: center;
  align-self: center;
  border: none;
  box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.25);
}

.btn-cancel {
  background-color: #0b1846;
  border: 2px solid #0b1846;
  color: #ffeba7;
}

.btn-cancel:hover {
  background-color: transparent;
  color: #0b1846;
}

.close {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 110;
  color: #ffeba7;
  cursor: pointer;
  box-shadow: 0 12px 25px 0 rgba(16, 39, 112, 0.25);
  transition: all 200ms linear;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
}

.close:hover {
  transform: translateY(-4px);
}

.closeAfter {
  transition: opacity 300ms 300ms ease, transform 300ms 300ms ease,
    background-color 250ms linear, color 250ms linear;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.modal {
  background-color: rgba(31, 32, 41, 0.75);
  transition: opacity 250ms 200ms ease;
}

.modalVisible {
  transition: all 300ms ease-in-out;
}

.modal-wrap {
  position: relative;
  width: 40rem;
  min-height: 20rem;
  max-width: 50rem;
  border-radius: 8px;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  opacity: 0;
  transform: scale(0.6);
  transition: opacity 250ms 100ms ease, transform 300ms 100ms ease;
}

.modal-wrapVisable {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms 100ms ease, transform 350ms 100ms ease;
}
</style>
