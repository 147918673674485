import * as API from "./API";

import store from "../store";

export default {

    createAdministrator(administrator) {
        return API.apiClient.post(`/api/Administrator/add_Medical_supplies_user`, administrator, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    getAdministrators(page_number, page_size, id) {
        return API.apiClient.get(`/api/Administrator/get_users_by_medical_supplies_id?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },




    getMedicalSuppliesWithoutPagination() {
        return API.apiClient.get(`/api/Medical_supplies/GetAll`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMedicalSupplies(page_number, page_size, name, id_administrator) {
        return API.apiClient.get(`/api/Medical_supplies/GetAllMedical_supplies?page_number=${page_number}&page_size=${page_size}&name=${name}&id_administrator=${id_administrator}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMedicalSupply(id) {
        return API.apiClient.get(`/api/Medical_supplies/GetByID?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createMedicalSupply(medicalsupply) {
        return API.apiClient.post(`/api/Medical_supplies/Add`, medicalsupply, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteMedicalSupply(id) {
        return API.apiClient.delete(`/api/Medical_supplies/Delete?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateMedicalSupply(medicalsupply) {
        return API.apiClient.put(`/api/Medical_supplies/Update`, medicalsupply, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

};