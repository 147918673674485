<template>
  <div class="relative w-96 mt-4">
    <button
      type="button"
      :class="isOpen ? 'ring-2 ring-primary-100' : ''"
      class="bg-white w-full flex items-center gap-6 mt-2  border border-gray-300 p-2 rounded-lg transition-all duration-200 cursor-pointer "
      @click="isOpen = !isOpen"
    >
      <ArrowIcon :is-open="isOpen" />
      <span>
        {{ title }}
      </span>
    </button>
    
    <div
      :class="isOpen ? 'opacity-100 pointer-events-auto translate-y-0' : 'opacity-0 pointer-events-none -translate-y-3'"
      class="bg-white w-full mt-2 shadow-dropdown rounded-md absolute transition-all duration-200 z-50"
    >
      <ul class="p-2 max-h-[20rem] screen:overflow-y-auto">
        <li
          v-for="(state, index) in orderStates"
          :key="state.id"
          class="hover:bg-gray-100 cursor-pointer p-2 rounded"
          :class="{ 'bg-gray-500 text-white hover:bg-gray-600': index === currentIndex, 'mt-2': index > 0 }"
          @click="selectItem(state, index)"
        >
          {{ state.label }}
        </li>
      </ul>
    </div>
  </div>
</template>
    
    <script>
    import ArrowIcon from "../../../medicalSuppliesFolder/medicalSupplyOrders/components/icons/ArrowIcon.vue"
    import { STATUS } from "../../../medicalSuppliesFolder/medicalSupplyOrders/models/status";
    
    export default {
    
      components: {
        ArrowIcon
      },
    
      data() {
        return {
          orderStates: [
            {
              id: STATUS.ALL,
              label: "كل الطلبات"
            },
            {
              id: STATUS.PENDING,
              label: "الطلبات الجديدة"
            },
            {
              id: STATUS.APPROVED,
              label: "الطلبات الموافق عليها"
            },
            {
              id: STATUS.RESERVED,
              label: "الطلبات المحجوزة"
            },
            {
              id: STATUS.DELIVERED,
              label: "الطلبات المسلمة من قبل البلدية"
            },
            {
              id: STATUS.RESEIVED,
              label: " الطلبات المستلمة من المركز"
            },
            {
              id: STATUS.DENIED,
              label: "الطلبات المرفوضة"
            }
          ],
    
          isOpen: false,
          title: "كل الطلبات",
          currentIndex: 0
        }
      },
    
      methods: {
        selectItem(state, index) {
          this.isOpen = !this.isOpen
          this.title = state.label
          this.currentIndex = index
          this.$emit('handle-dropdown', state);
        }
      },
    }
    
    </script>