<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        المسؤولين
      </h1>

      <router-link
        title="إضافة"
        :to="{
          name: 'addadministratorfromMedicalSupplies',
          params: { MedicalSupplies_id: MedicalSupplies_id },
        }"
        class="w-28 md:w-40 text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        إضافة +
      </router-link>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <div
            class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
          >
            <div scope="col" class="w-1/12 px-6 py-3 hidden lg:block">
              الرقم
            </div>
            <div scope="col" class="w-7/12 md:w-4/12 lg:w-3/12 px-6 py-3">
              الاسم
            </div>
            <div scope="col" class="w-3/12 lg:w-2/12 px-6 py-3 hidden md:block">
              اسم المستخدم
            </div>

            <div scope="col" class="w-3/12 lg:w-4/12 px-6 py-3 hidden md:block">
              الصفة
            </div>

            <div scope="col" class="w-5/12 md:w-2/12 px-6 py-3">الإجراءات</div>
          </div>
          <div v-for="(administrator, personIdx) in administratorsData" :key="administrator.id"
                        class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400" 
                        :class=" personIdx % 2 === 0 ? 'bg-white dark:bg-slate-800' : 'bg-gray-50 dark:bg-slate-800' "
                    >
                        <div class="w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden lg:block">
                            {{ personIdx+1 }}
                        </div>
                        <div class="w-7/12 md:w-4/12 lg:w-3/12 px-6 py-4 whitespace-nowrap text-sm truncate">
                            {{ administrator.full_name }} 
                        </div>
                        <div class="w-3/12 lg:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ administrator.usename }}
                        </div>

                        <div class="w-3/12 lg:w-4/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ administrator.role_name }}
                        </div>

                        <div class="w-5/12 md:w-2/12 px-6 py-4">
                            <div class="w-full flex items-center justify-between">

                       
<!-- 
                                <button
                  title="إعادة تعيين كلمة مرور"
                  class=""
                  @click="
                    prepare_to_reset_password(
                      administrator.id,
                      administrator.usename
                    )
                  "
                >
                  <svg
                    class="h-6 w-6 stroke-current hover:text-red-600"
                    width="800px"
                    height="800px"
                    viewBox="0 0 21 21"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      transform="matrix(0 1 1 0 2.5 2.5)"
                    >
                      <path
                        d="m3.98652376 1.07807068c-2.38377179 1.38514556-3.98652376 3.96636605-3.98652376 6.92192932 0 4.418278 3.581722 8 8 8s8-3.581722 8-8-3.581722-8-8-8"
                      />

                      <path d="m4 1v4h-4" transform="matrix(1 0 0 -1 0 6)" />
                    </g>
                  </svg>
                </button> -->

                
                            </div>
                        </div>
                    </div>
        </table>
      </div>
    </div>

    <div
      class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"
    >
      <pagination
                dir="rtl"
                v-model="page_number"
                :per-page="page_size"
                :records="total"
                @paginate="getAdministrators"
                class="z-10"
            />
    </div>
  </div>
</template>

<script>
// import axios from "axios";

import MedicalSuppliesService from "@/services/MedicalSuppliesService";

export default {
  // components: { pagination: LaravelVuePagination },
  computed: {},
  data() {
    return {
      administratorsData: {},
      page_number: 1,
      page_size: 10,
      total: 0,

      MedicalSupplies_id: this.$route.params.municipal_id,

      prepare_to_reset_password_model: false,

      new_password: "",
      conform_new_password: "",
      username_to_reset_password: "",
      id_to_reset_password: "",
    };
  },
  created() {
    this.getAdministrators();
  },
  methods: {
    prepare_to_reset_password(id, username) {
      this.prepare_to_reset_password_model = true;

      this.conform_new_password = "";
      this.new_password = "";
      this.username_to_reset_password = username;
      this.id_to_reset_password = id;

      // /api/Administrator/rest_password
    },

    reset_password() {
      const data = {
        userName: this.username_to_reset_password,
        password: this.conform_new_password,
      };

      let loader = this.$loading.show({
        loader: this.loader,
      });

      MedicalSuppliesService.resetPassword(data)
        .then((resp) => {
          this.prepare_to_reset_password_model = false;

          loader.hide();
          this.$swal.fire({
            text: resp.data.message,
            icon: "success",
            confirmButtonText: "حسنا",
          });
        })
        .catch((err) => {
          loader.hide();
          this.prepare_to_reset_password_model = false;

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.errors.name,
          });
        });
    },

    // getPrivilegesKey(key) {
    //     return Object.keys(key);
    // },
    getAdministrators() {
      let loader = this.$loading.show({
        loader: this.loader,
      });
      MedicalSuppliesService.getAdministrators(this.page_number, this.page_size, this.MedicalSupplies_id)
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            this.administratorsData = res.data.list;

            console.log(this.administratorsData);
            this.total = res.data.total;
          }, 10);
        })
        .catch((err) => {
          loader.hide();
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    deleteAdministrator(id) {
      this.$swal
        .fire({
          title: "هل أنت متأكد؟",
          text: "!لن تتمكن من التراجع عن هذا",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "إلغاء",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "!نعم ، احذفها",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show({
              loader: this.loader,
            });

            MedicalSuppliesService.deleteAdministrator(id)
              .then((resp) => {
                let i = this.administratorsData
                  .map((item) => item.id)
                  .indexOf(id); // find index of your object
                this.administratorsData.splice(i, 1);
                loader.hide();
                this.$swal.fire({
                  text: resp.data.message,
                  icon: "success",
                  confirmButtonText: "حسنا",
                });
              })
              .catch((err) => {
                loader.hide();

                this.$swal.fire({
                  icon: "error",
                  title: "...عذرا",
                  text: err.response.data.errors.name,
                });
              });
          }
        });
    },
  },
};
</script>

<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  /* margin-left: .5rem;
        margin-right: .5rem;*/
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
