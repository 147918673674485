<template>
  <div class="bg-gray-300 min-h-screen w-full absolute top-0 left-0 z-50">
    <div
      class="min-h-screen mx-auto px-6 pt-8 text-xs background-tik relative"
      style="width: 210mm"
    >
      <div
        id="printPageButton"
        class="w-full absolute left-4 bottom-6 z-50 flex justify-end gap-4 items-center"
      >
        <router-link
          to="/ms3040/medical-supplies/b6d0f91e-868f-4cee-9d8f-df13d5ce5840/order/delivery"
          class="text-sm text-center font-bold text-white bg-blue-500 hover:bg-blue-400 cursor-pointer duration-300 w-48 py-2"
        >
          رجوع
        </router-link>
        <button
          type="submit"
          class="text-sm font-bold text-white bg-green-500 hover:bg-green-400 cursor-pointer duration-300 w-48 py-2"
          @click="print"
        >
          طباعة
        </button>
      </div>
      <div class="flex justify-between items-center">
        <div class="text-center space-y-1">
          <p>وزارة الصحة</p>
          <p class="font-semibold">
            المركز الوطني لمكافحة الأمراض
          </p>
          <p class="font-semibold">
            مكتب الصيدلة والمعدات الطبية
          </p>
          <p class="text-xs">
            إذن طلب \صرف \ إستلام مهمات
          </p>
        </div>
        <p>INDENT OD ISSUE RECEIPT VOUCHER</p>
      </div>

      <div class="mt-4 flex justify-between items-center">
        <p>إستمارة المخازن الطبية رقم (1209)</p>
        <p>MEDICAL STORES FORM (1209)</p>
      </div>

      <div class="mt-1 flex justify-center">
        <span class="border-b-2 border-dashed border-gray-800">NO: 524423</span>
      </div>

      <div class="mt-1 flex justify-between items-center">
        <p>
          أدوات ومهمات طبية لإستعمالها في :
          <span class="border-b-2 border-dashed">الرعاية الصحية</span>
        </p>
        <p>: DRUGS AND EQUIPMENT FOR USE OF</p>
      </div>

      <div class="mt-1 flex justify-center gap-4">
        <span>عن المدة</span>
        <span class="border-b-2 border-dashed border-gray-800">2024/2/23</span>
        <span>: FOR PERIOD </span>
      </div>

      <p class="mt-2 text-left">
        NOTE: INDENTS SHOULD BE ARRANGED IN SECTIONS SEPARATE FORMS BEING USED
        FOR EACH SECTION
      </p>

      <div>
        <div class="grid grid-cols-2 md:grid-cols-medicalForm mt-8 text-center">
          <div>
            <div class="border border-gray-700 p-4 space-y-2">
              <p>لإستعمال المخازن الطبية فقط</p>
              <p>For Medical Stores Use Only</p>
            </div>
            <div class="flex">
              <div class="w-full p-4 border border-gray-700">
                <p>الملاحظات</p>
                <p>REMARKS</p>
              </div>
              <div class="w-full p-4 border border-gray-700">
                <p>المنصرفات</p>
                <p>ISSUE</p>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col justify-center items-center border border-gray-700"
          >
            <p>الأدوات الطبية</p>
            <p>Stock Required</p>
          </div>
          <div
            class="flex flex-col justify-center items-center border border-gray-700"
          >
            <p>الأدوات الموجودة بالمخازن</p>
            <p>Hand Stoke In</p>
          </div>
          <div
            class="flex flex-col justify-center items-center border border-gray-700"
          >
            <p>خانة الأدوات</p>
            <p>Auricles Section</p>
          </div>
          <div
            class="flex flex-col justify-center items-center border border-gray-700 px-2"
          >
            <p class="leading-5">
              رقم صحيفة سجل المخازن الطبية
            </p>
            <p class="leading-5">
              Med Stores File No.
            </p>
          </div>
        </div>

        <!-- <div
          v-for="vaccin in vaccineInformation"
          :key="vaccin.id"
          class="mt-8 grid grid-cols-2 md:grid-cols-deliveryModal items-baseline"
        >
          <p
            class="border border-gray-700 py-1 px-3 rounded-md"
            style="width: fit-content"
          >
            {{ vaccin.vaccine_name }}
          </p>

          <p
            class="border border-gray-700 py-1 px-3 rounded-md"
            style="width: fit-content"
          >
            {{ vaccin.batch_number }}
          </p>

          <p
            class="border border-gray-700 py-1 px-3 rounded-md"
            style="width: fit-content"
          >
            {{ vaccin.production_date }}
          </p>

          <p
            class="border border-gray-700 py-1 px-3 rounded-md"
            style="width: fit-content"
          >
            {{ vaccin.quantity }}
          </p>

          <p
            class="border border-gray-700 py-1 px-3 rounded-md"
            style="width: fit-content"
          >
            {{ vaccin.quantity }}
          </p>

          <p
            class="border border-gray-700 py-1 px-3 rounded-md"
            style="width: fit-content"
          >
            {{ vaccin.quantity }}
          </p>
        </div> -->
      </div>

      <div class="grid grid-cols-3">
        <div class="px-3 pt-1">
          <p class="mt-1">
            أقر بأن الأدوات المطلوبه بهذا الإذن ضرورية
          </p>
          <p class="mt-2">
            I verify that the articles demanded are necessary
          </p>
          <div class="mt-3 flex gap-1">
            <span>التوقيع </span>
            <span>/ Signature :</span>
          </div>
          <p class="mt-3">
            ......................................................................
          </p>
          <div class="mt-3 flex">
            <Span>الطبيب المسؤول</Span>
            <Span>/ Medical Officer In :</Span>
          </div>
          <p class="mt-4">
            ......................................................................
          </p>
        </div>

        <div class="px-3 py-2 border-r border-l border-gray-700">
          <p class="text-center">
            ( الصيدلية )
          </p>
          <div class="flex my-3">
            <span>الصفة </span>
            <span>/ Issue approved :</span>
          </div>
          <p class="mt-7">
            ......................................................................
          </p>
          <div class="flex gap-1 mt-7">
            <span>التوقيع</span>
            <span>/ Signature :</span>
          </div>
          <p class="mt-6">
            ......................................................................
          </p>
        </div>

        <div class="p-3 pt-6">
          <span>توقيع ضابط الصرف : </span>
          <span> / Sign or Issuing Officer : </span>
          <p class="mt-6">
            ......................................................................
          </p>
        </div>

        <div class="p-2 border-t border-b border-gray-700">
          <p class="leading-6">
            لا يجب إدخال أي تعديل في هذا العمود و أي ملاحظة أخري يجب أن تعود في
            إرسالية منفصلة عن هذا الإذن .
          </p>
          <p class="mt-3">
            .NO alterations to made to the figures in *
          </p>
        </div>

        <div class="pr-3 border border-gray-700 p-2">
          <div class="flex">
            <span>الصرف</span>
            <span>/ Issues :</span>
          </div>
          <p class="mt-4">
            ......................................................................
          </p>
          <div class="flex mt-1">
            <span>إذن إستلام رقم </span>
            <span> / Receipt Voucher No :</span>
          </div>
          <p class="mt-4">
            ......................................................................
          </p>
          <div class="flex mt-1">
            <span>الحساب</span>
            <span>/ Account :</span>
          </div>
          <p class="mt-5">
            ......................................................................
          </p>
          <div class="mt-1">
            <p>تاريخ إبتداء الحساب :</p>
            <p class="mt-4">
              ......................................................................
            </p>
          </div>
        </div>

        <div class="p-3 border-t border-b border-gray-700">
          <p>أستلمت المهمات المبينة أعلاه</p>
          <p class="mt-1">
            Received the above tasks
          </p>
          <div class="flex gap-1 mt-5">
            <span>التوقيع</span>
            <span>/ Signature :</span>
          </div>
          <p class="mt-6">
            ......................................................................
          </p>
          <div class="mt-6 flex">
            <span>التاريخ</span>
            <span>/ Date :</span>
          </div>
          <p class="mt-6">
            ......................................................................
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    print() {
      window.print();
    },
  },
};
</script>

<style>
@media print {
}

@page {
  size: A4;
  margin-top: 0.5cm;
  margin-bottom: 0.5cm;
  margin-right: 0.35cm;
  margin-left: 0cm;
}
</style>
