<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">
        طلبات الامداد  
      </h1>

      <router-link
        title="طبب شحنة جديدة"
        :to="{
          name: 'requestVaccinesForMunicipals',
          params: { id: this.$route.params.id },
        }"
        class="w-28 md:w-40 text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        طبب شحنة جديدة +
      </router-link>
    </div>

    <OrderStateDropdown @handle-dropdown="handleDropdown" />

    <!-- <div class="mt-4 flex items-center justify-between w-full relative">
      <div class="h-8">
        <button
          class="flex items-center hover:text-blue-600 cursor-pointer w-32"
          @click="toggle_filter()"
        >
          <svg
            class="h-6 w-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path
              d="M2 7h.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2H9.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2zm4-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm24 11h-.142a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h20.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-4 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm4 7H19.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h10.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-14 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"
            />
          </svg>
          <span class="mr-2"> فرز </span>
        </button>
      </div> -->

    <!-- <div class="hidden xl:flex xl:ml-0 h-8">
                <label for="search-field" class="sr-only">Search</label>
                <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path
                                fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                    <input
                        id="search-field"
                        class="block h-full bg-gray-50 dark:bg-slate-900 w-full border-transparent py-2 pr-8 dark:text-gray-400 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="بحث...."
                        type="search"
                        name="search"
                        v-model="filter_search"
                    />
                </div>
            </div> -->

    <!-- <transition
                enter-active-class="transition ease-in-out duration-200"
                enter-class="transform opacity-0 scale-y-0"
                enter-to-class="transform opacity-100 scale-y-100"
                leave-active-class="transition ease-in-out duration-75"
                leave-class="transform opacity-100 scale-y-100"
                leave-to-class="transform opacity-0 scale-y-0"
            >

                <div v-if="filter_box" class="absolute z-40 w-full top-8 sm:rounded-b-lg border-t-2 border-gray-700 dark:border-slate-400 bg-gray-300 dark:bg-slate-700 dark:bg-opacity-90 bg-opacity-90 px-4 pt-4 pb-8">

                    <div class="grid grid-cols-4 gap-8">
                        <div class="">
                            <label for="medical_supply_id" class="block text-sm font-medium ">
                                    موافقة ادارة التطعيمات
                            </label>
                            <select
                                id="is_approved"
                                name="is_approved"
                                v-model="filter_is_approved"
                                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                                <option  disabled selected>الحالات</option>

                                <option v-bind:value="0">
                                    الكل
                                </option>

                                <option v-bind:value="1">
                                    تمت الموافقة
                                </option>

                                <option v-bind:value="2">
                                    تم الرفض
                                </option>

                                <option v-bind:value="3">
                                    قيد المعالجة
                                </option>

                            </select>
                        </div>
                    </div>

                </div>

            </transition> -->
    <!-- </div> -->

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
          <div
            class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
          >
            <div
              scope="col"
              class="w-7/12 md:w-4/12 px-4 py-3"
            >
              اسم الصنف
            </div>

            <div
              scope="col"
              class="w-3/12 md:w-3/12 px-6 py-3 hidden md:block"
            >
              Item name
            </div>

            <div
              scope="col"
              class="w-3/12 md:w-1/12 px-6 py-3 hidden md:block"
            >
              الكمية
            </div>

            <div
              scope="col"
              class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block"
            >
              حالة الطلب
            </div>

            <div
              scope="col"
              class="w-3/12 md:w-1/12 px-2 py-3 hidden md:block"
            >
              تاريخ الطلب
            </div>

            <div
              scope="col"
              class="w-5/12 md:w-1/12 px-6 py-3"
            >
              الإجراءات
            </div>
          </div>


          <div
            v-if="total == 0"
            class="h-40 w-full bg-gray-200 dark:bg-gray-700 flex justify-center items-center"
          >
            لا توجد طلبات.
          </div>


          <div
            v-for="(order, personIdx) in vaccination_center_orders_data"
            v-else
            :key="order.id"
            class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            :class="personIdx % 2 === 0
              ? 'bg-white dark:bg-slate-800'
              : 'bg-gray-200 dark:bg-slate-800'
            "
          >
            <div class="w-7/12 md:w-4/12 px-4 py-4  text-sm ">
              {{ order.vaccine_Name }}
            </div>

            <div class="w-3/12 md:w-3/12 px-6 py-4  text-sm   hidden md:block">
              {{ order.vaccine_Name_En }}
            </div>

            <div class="md:w-1/12 px-6 py-4  text-sm truncate hidden md:block">
              {{ order.quantity }}
            </div>

            <div class="w-3/12 md:w-2/12 px-2 py-4  text-sm truncate hidden md:block">
              <span
                class="font-bold py-1 px-2 text-white rounded-full"
                :class="order.order_state | municipal_order_state_style"
              >
                {{ order.order_state | municipal_order_state }}
              </span>
            </div>

            <div class="md:w-1/12 px-2 py-4  text-sm truncate hidden md:block">
              {{ order.insert_at }}
            </div>

            <div class="w-5/12 md:w-1/12 px-6 py-4">
              <div class="w-full flex items-center justify-between">
                <div class="w-12">
                  <!-- <button
                    v-if="order.order_state == 1"
                    title="حذف"
                    class="px-1"
                    @click="deleteOrder(order.id)"
                  >
                    <svg
                      class="h-6 w-6 stroke-current hover:text-red-600"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.80444 6.50317H5.80444H21.8044"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.80444 6.50317V4.50317C8.80444 3.97274 9.01516 3.46403 9.39023 3.08896C9.7653 2.71389 10.274 2.50317 10.8044 2.50317H14.8044C15.3349 2.50317 15.8436 2.71389 16.2187 3.08896C16.5937 3.46403 16.8044 3.97274 16.8044 4.50317V6.50317M19.8044 6.50317V20.5032C19.8044 21.0336 19.5937 21.5423 19.2187 21.9174C18.8436 22.2925 18.3349 22.5032 17.8044 22.5032H7.80444C7.27401 22.5032 6.7653 22.2925 6.39023 21.9174C6.01516 21.5423 5.80444 21.0336 5.80444 20.5032V6.50317H19.8044Z"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button> -->

                  <button
                    v-if="order.order_state === 4"
                    title="إقرار بالإستلام"
                    class="cursor-pointer"
                    @click="municipalityReceivedOrder(order.id)"
                  >
                    <DeliverIcon />
                  </button>
                </div>
                <!-- <div class="w-12">
                  <router-link
                    v-if="
                      order.center_approval == null &&
                      order.order_state < 2 &&
                      order.order_by == 2
                    "
                    title="تعديل"
                    :to="{
                      name: 'editVaccineToMunicipalOrder',
                      params: { id: vaccination_center, order: order.id },
                    }"
                  >
                    <svg
                      class="h-6 w-6 stroke-current hover:text-blue-600"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div> -->

                <!-- <div class="w-16">
                  <div
                    v-if="
                      order.municipal_approval == null && order.order_state < 2
                    "
                    class="flex justify-between"
                  >
                    <button
                      title="الموافقة على الطلب"
                      class=""
                      @click="orderApproval(order.id)"
                    >
                      <svg
                        class="w-6 h-6 stroke-current hover:text-green-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="4"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    </button>

                    <button
                      title="رفض الطلب"
                      class=""
                      @click="openOrderRejectModel(order.id)"
                    >
                      <svg
                        class="w-6 h-6 fill-current hover:text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div class="w-12">
                  <div class="" v-if="order.order_state == 2 || order.order_state == 3">
                    <button
                      v-if="order.quantity == 0"
                      title="تحديد الكمية"
                      class="cursor-pointer"
                      @click="
                        getMonthlyVaccinationMovement(
                          order.health_center_id,
                          order.municipal_id,
                          order.muncipal_name,
                          order.id,
                          order.vaccine_id,
                          true,
                          order.quantity,
                          order.insert_at,
                          order.order_state
                        )
                      "
                    >
                      <svg
                        class="w-6 h-6 stroke-current hover:text-blue-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                        ></path>
                      </svg>
                    </button>

                    <button
                      v-else
                      title="تعديل بيانات الطلب"
                      class="cursor-pointer"
                      @click="
                        getMonthlyVaccinationMovement(
                          order.health_center_id,
                          order.municipal_id,
                          order.muncipal_name,
                          order.id,
                          order.vaccine_id,
                          false,
                          order.quantity,
                          order.insert_at,
                          order.order_state
                        )
                      "
                    >
                      <svg
                        class="w-6 h-6 stroke-current hover:text-blue-600"
                        width="34"
                        height="24"
                        viewBox="0 0 34 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 3H15L15.4 5M15.4 5H31L27 13H17M15.4 5L17 13M17 13L14.707 15.293C14.077 15.923 14.523 17 15.414 17H27M27 17C26.4696 17 25.9609 17.2107 25.5858 17.5858C25.2107 17.9609 25 18.4696 25 19C25 19.5304 25.2107 20.0391 25.5858 20.4142C25.9609 20.7893 26.4696 21 27 21C27.5304 21 28.0391 20.7893 28.4142 20.4142C28.7893 20.0391 29 19.5304 29 19C29 18.4696 28.7893 17.9609 28.4142 17.5858C28.0391 17.2107 27.5304 17 27 17ZM19 19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21C16.4696 21 15.9609 20.7893 15.5858 20.4142C15.2107 20.0391 15 19.5304 15 19C15 18.4696 15.2107 17.9609 15.5858 17.5858C15.9609 17.2107 16.4696 17 17 17C17.5304 17 18.0391 17.2107 18.4142 17.5858C18.7893 17.9609 19 18.4696 19 19Z"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <g clip-path="url(#clip0_3_6)">
                          <path
                            d="M8.56956 5.84607C8.56646 5.6449 8.60301 5.44509 8.67714 5.25805C8.75126 5.07101 8.86149 4.90039 9.00155 4.75595C9.1416 4.61151 9.30874 4.49607 9.49341 4.41623C9.67808 4.33638 9.87667 4.29368 10.0778 4.29058C10.279 4.28748 10.4788 4.32404 10.6659 4.39816C10.8529 4.47228 11.0235 4.58252 11.168 4.72257C11.3124 4.86263 11.4278 5.02976 11.5077 5.21443C11.5875 5.3991 11.6302 5.59769 11.6333 5.79886L11.7927 16.1391L10.3021 18.8435L8.72889 16.1863L8.56956 5.84607Z"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3_6">
                            <rect
                              width="13"
                              height="13"
                              fill="white"
                              transform="translate(0.992762 11.3257) rotate(-45.8828)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </table>
      </div>
    </div>

    <div class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative">
      <pagination
        v-model="page_number"
        dir="rtl"
        :per-page="page_size"
        :records="total"
        class="z-10"
        @paginate="getMuncipalOrder"
      />
    </div>

    <div
      v-if="order_reject_model"
      class="fixed z-40 bg-black bg-opacity-70 inset-0"
    >
      <div class="min-h-screen flex justify-center items-center">
        <form
          class="w-1/3 px-4 text-slate-500 dark:text-slate-400 border-2 border-gray-600 bg-gray-50 dark:bg-slate-900 py-4"
          @submit.prevent="OrderReject()"
        >
          <div class="flex justify-between items-center">
            <div class="">
              ادكر سبب رفض الطلب
            </div>
            <div class="">
              <button
                class="hover:text-red-500 cursor-pointer"
                @click="order_reject_model = false"
              >
                X
              </button>
            </div>
          </div>

          <hr class="bg-gray-400 h-0.5 mt-4">

          <div
            class="input mt-6 px-4"
            :class="{ invalid: $v.rejectOrder.reason.$error }"
          >
            <label
              for="rejectOrder_reason"
              class="block text-sm font-medium"
            >
              السبب
            </label>
            <input
              id="rejectOrder_reason"
              v-model="rejectOrder.reason"
              type="text"
              placeholder="أكتب السبب."
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 text-black bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.rejectOrder.reason.$touch()"
            >
          </div>

          <div class="text-white md:w-auto mt-8 w-full">
            <div
              class="w-full md:w-auto"
              :class="rejectOrder.reason == '' ? 'cursor-not-allowed' : 'cursor-pointer'"
            >
              <button
                :disabled="rejectOrder.reason == ''"
                type="submit"
                class="border border-transparent rounded-md shadow-sm w-full py-2 px-16 text-sm font-medium focus:outline-none"
                :class="rejectOrder.reason == ''
                  ? 'bg-gray-600'
                  : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                "
              >
                حفظ
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div
      v-if="quantity_model"
      class="fixed z-40 bg-black bg-opacity-60 inset-0"
    >
      <div class="min-h-screen flex justify-center items-center">
        <form
          class="w-4/5 bg-white py-4"
          @submit.prevent="addMedicalSupply"
        >
          <div class="">
            <button
              class="hover:text-red-500 cursor-pointer"
              @click="quantity_model = false"
            >
              X
            </button>
          </div>

          <!-- <div class="px-4 flex justify-between items-center">
            <div class="">
             
              حركة الطعوم الشهرية
            </div>
           
          </div>

          <hr class="bg-gray-400 h-0.5 mt-4" />

          <div class="grid grid-cols-6 gap-3 p-4">
            <div class="">
              <label
                for="determine_quantity_and_send_to_cart_quantity"
                class="block text-sm font-medium"
              >
                اسم اللقاح
              </label>
              <div
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 sm:text-sm"
              >
                {{ monthly_vaccination_movement_data.vaccine_name }}
              </div>
            </div>

            <div class="">
              <label
                for="determine_quantity_and_send_to_cart_quantity"
                class="block text-sm font-medium"
              >
                مركز التطعيم
              </label>
              <div
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 sm:text-sm"
              >
                {{ monthly_vaccination_movement_data.health_center_name }}
              </div>
            </div>

            <div class="">
              <label
                for="determine_quantity_and_send_to_cart_quantity"
                class="block text-sm font-medium"
              >
                البلدية
              </label>
              <div
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 sm:text-sm"
              >
                {{ determine_quantity_and_send_to_cart.municipal_name }}
              </div>
            </div>

            <div class="">
              <label
                for="determine_quantity_and_send_to_cart_quantity"
                class="block text-sm font-medium"
              >
                الكميةالموجودة في الثلاجة الان
              </label>
              <div
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 sm:text-sm"
              >
                {{
                  monthly_vaccination_movement_data.the_quantity_in_the_refrigerator
                }}
              </div>
            </div>

            <div class="">
              <label
                for="determine_quantity_and_send_to_cart_quantity"
                class="block text-sm font-medium"
              >
                تاريخ الطلب
              </label>
              <div
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 sm:text-sm"
              >
                {{ determine_quantity_and_send_to_cart.date_of_order }}
              </div>
            </div>

            <div class="">
              <label
                for="determine_quantity_and_send_to_cart_quantity"
                class="block text-sm font-medium"
              >
                حالة الطلب
              </label>
              <div
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 sm:text-sm"
              >
                {{
                  determine_quantity_and_send_to_cart.order_state
                    | administration_order_state
                }}
              </div>
            </div>
          </div>

          <div class="px-4">
            <table
              class="min-w-full divide-y divide-gray-200 border-b-4 border-gray-100"
            >
              <div
                class="w-full flex bg-gray-50 text-slate-500 text-xs font-medium tracking-wider text-right"
              >
                <div scope="col" class="w-2/12 px-4 py-3">الشهر</div>
                <div scope="col" class="w-2/12 px-4 py-3">
                  المتبقي من الشهر السابق
                </div>

                <div scope="col" class="w-2/12 px-4 py-3">المستلم</div>

                <div scope="col" class="w-2/12 px-4 py-3">الاجمالي</div>

                <div scope="col" class="w-2/12 px-4 py-3">عدد المطعمين</div>

                <div scope="col" class="w-2/12 px-4 py-3">الفاقد</div>
              </div>

              <div class="h-64 overflow-y-scroll">
                <div
                  v-for="(
                    vaccine, index
                  ) in monthly_vaccination_movement_data.movements"
                  :key="index"
                  class="hover:bg-gray-100 flex text-slate-500 bg-white"
                >
                  <div class="w-2/12 px-4 py-1.5 text-sm truncate">
                    {{ vaccine.name_of_month }}
                  </div>

                  <div class="w-2/12 px-4 py-1.5 text-sm truncate">
                    {{ vaccine.remaining_month_is_vaccine }}
                  </div>

                  <div class="w-2/12 px-4 py-1.5 text-sm truncate">
                    {{ vaccine.vaccine_received }}
                  </div>

                  <div class="w-2/12 px-4 py-1.5 text-sm truncate">
                    {{
                      vaccine.vaccine_received +
                      vaccine.remaining_month_is_vaccine
                    }}
                  </div>

                  <div class="w-2/12 px-4 py-1.5 text-sm truncate">
                    {{ vaccine.the_number_of_vaccinators }}
                  </div>

                  <div class="w-2/12 px-4 py-1.5 text-sm truncate">
                    {{ vaccine.wasted }}
                  </div>
                </div>
              </div>
            </table>
          </div> -->

          <div class="flex justify-center items-center border border-gray-700 rounded-lg w-3/5 mx-auto mt-2 p-4">
            <!-- :class="{
                invalid: $v.determine_quantity_and_send_to_cart.quantity.$error,
              }" -->
            <div class="input px-4">
              <label
                for="determine_quantity_and_send_to_cart_quantity"
                class="block text-sm font-medium"
              >
                الكمية
              </label>
              <!--   @blur="$v.determine_quantity_and_send_to_cart.quantity.$touch()" -->
              <input
                id="determine_quantity_and_send_to_cart_quantity"
                v-model="determine_quantity_and_send_to_cart.quantity"
                type="number"
                placeholder="أكتب الكمية."
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
            </div>

            <div class="text-white md:w-auto mt-5 px-4 w-full">
              <!-- :class="$v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer'"  -->
              <div class="w-full md:w-auto">
                <!-- :disabled="$v.$invalid" -->

                <!-- :class="
                    $v.$invalid
                      ? 'bg-gray-600'
                      : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  " -->
                <button
                  type="submit"
                  class="border border-transparent rounded-md shadow-sm w-full py-2 px-16 text-sm font-medium focus:outline-none
                  
                  bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                  "
                >
                  تحديد الكمية الجديدة
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import MunicipalsOrdersService from "@/services/MunicipalsOrdersService";
import OrderStateDropdown from "../../medicalSuppliesFolder/medicalSupplyOrders/components/OrderStateDropdown.vue"
import DeliverIcon from "../../medicalSuppliesFolder/medicalSupplyOrders/components/icons/DeliverIcon.vue"

import { required } from "vuelidate/lib/validators";

export default {

  components: {
    OrderStateDropdown,
    DeliverIcon
  },

  data() {
    return {
      vaccination_center_orders_data: {},
      page_number: 1,
      page_size: 10,
      total: 0,

      vaccination_center: this.$route.params.id,

      filter_box: false,

      filter_search: "",
      filter_is_approved: "",

      order_reject_model: false,
      order_state: 0,

      rejectOrder: {
        id: "",
        order_state: 6,
        reason: "",
      },

      quantity_model: false,

      determine_quantity_and_send_to_cart: {
        quantity: '',
        health_center_id: '',
        municipal_id: '',
        municipal_name: '',
        order_id: '',
        vaccine_id: '',
        is_it_add: '',
        date_of_order: '',
        order_state: '',
      },


      statusCode: '',

    };
  },
  computed: {},

  validations: {


    determine_quantity_and_send_to_cart: {
      quantity: {
        required,
      },
      health_center_id: {
        required,
      },
      municipal_id: {
        required,
      },
      order_id: {
        required,
      },
      vaccine_id: {
        required,
      },

    },


    rejectOrder: {
      id: {
        required,
      },
      order_state: {
        required,
      },
      reason: {
        required,
      },
    },
  },

  watch: {
    // filter_search: function() {
    //     this.getCitizens()
    // },
    // filter_is_approved: function() {
    //     if(this.filter_is_approved == 0){
    //         this.filter_is_approved = '';
    //     }
    //     this.getMuncipalOrder()
    // },
  },

  created() {
    this.getMuncipalOrder();
  },
  methods: {
    getMonthlyVaccinationMovement(
      health_center_id,
      municipal_id,
      municipal_name,
      order_id,
      vaccine_id,
      is_it_add,
      quantity,
      date_of_order,
      order_state
    ) {
      let loader = this.$loading.show({
        loader: this.loader,
      });








      this.determine_quantity_and_send_to_cart.quantity = quantity
      this.determine_quantity_and_send_to_cart.health_center_id = health_center_id
      this.determine_quantity_and_send_to_cart.municipal_id = municipal_id
      this.determine_quantity_and_send_to_cart.order_id = order_id
      this.determine_quantity_and_send_to_cart.vaccine_id = vaccine_id
      this.determine_quantity_and_send_to_cart.is_it_add = is_it_add



      console.log(this.determine_quantity_and_send_to_cart)


      console.log(health_center_id)
      console.log(municipal_id)
      console.log(municipal_name)
      console.log(order_id)
      console.log(vaccine_id)
      console.log(is_it_add)
      console.log(quantity)
      console.log(date_of_order)
      console.log(order_state)


      loader.hide();


      this.quantity_model = true;

      // MunicipalsOrdersService.getMonthlyVaccinationMovement(
      //   health_center_id,
      //   vaccine_id
      // )
      //   .then((res) => {



      // setTimeout(() => {
      //   this.quantity_model = true;
      //   this.determmine_quantity_and_send_to_cart.health_center_id =
      //     health_center_id;
      //   this.determine_quantity_and_send_to_cart.municipal_id =
      //     municipal_id;
      //   this.determine_quantity_and_send_to_cart.municipal_name =
      //     municipal_name;
      //   this.determine_quantity_and_send_to_cart.order_id = order_id;
      //   this.determine_quantity_and_send_to_cart.vaccine_id = vaccine_id;
      //   this.determine_quantity_and_send_to_cart.is_it_add = is_it_add;
      //   this.determine_quantity_and_send_to_cart.quantity = quantity;
      //   this.determine_quantity_and_send_to_cart.date_of_order =
      //     date_of_order;
      //   this.determine_quantity_and_send_to_cart.order_state = order_state;

      //   loader.hide();

      //   this.monthly_vaccination_movement_data = res.data;


      // }, 10);
      // })
      // .catch((err) => {
      //   loader.hide();

      //   this.$swal.fire({
      //     icon: "error",
      //     title: "...عذرا",
      //     text: err.response.data.message,
      //   });
      // });
    },

    addMedicalSupply() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data = {
        municipal_id: this.determine_quantity_and_send_to_cart.municipal_id,
        vaccine_id: this.determine_quantity_and_send_to_cart.vaccine_id,
        health_center_id:
          this.determine_quantity_and_send_to_cart.health_center_id,
        quantity: Number(this.determine_quantity_and_send_to_cart.quantity),
        id: this.determine_quantity_and_send_to_cart.order_id,
      };

      if (this.determine_quantity_and_send_to_cart.is_it_add == true) {
        MunicipalsOrdersService.quantification_order(data)
          .then((resp) => {
            this.quantity_model = false;
            this.getMuncipalOrder();

            this.determine_quantity_and_send_to_cart.health_center_id = "";
            this.determine_quantity_and_send_to_cart.municipal_id = "";
            this.determine_quantity_and_send_to_cart.order_id = "";
            this.determine_quantity_and_send_to_cart.vaccine_id = "";
            this.determine_quantity_and_send_to_cart.quantity = "";

            setTimeout(() => {
              loader.hide();
              this.$swal.fire({
                icon: "success",
                text: resp.data.message,
              });
            }, 10);
          })
          .catch((err) => {
            this.quantity_model = false;

            loader.hide();

            this.errors = err.response.data.message;
            this.$swal.fire({
              icon: "error",
              title: "...عذرا",
              text: err.response.data.message,
            });
          });
      } else {
        MunicipalsOrdersService.update_quantification_order(data)
          .then((resp) => {
            this.quantity_model = false;
            this.getMuncipalOrder();

            this.determine_quantity_and_send_to_cart.health_center_id = "";
            this.determine_quantity_and_send_to_cart.municipal_id = "";
            this.determine_quantity_and_send_to_cart.order_id = "";
            this.determine_quantity_and_send_to_cart.vaccine_id = "";
            this.determine_quantity_and_send_to_cart.quantity = "";

            setTimeout(() => {
              loader.hide();
              this.$swal.fire({
                icon: "success",
                text: resp.data.message,
              });
            }, 10);
          })
          .catch((err) => {
            this.quantity_model = false;

            loader.hide();

            this.errors = err.response.data.message;
            this.$swal.fire({
              icon: "error",
              title: "...عذرا",
              text: err.response.data.message,
            });
          });
      }
    },

    deleteOrder(id) {
      this.$swal
        .fire({
          title: "هل أنت متأكد؟",
          text: "!لن تتمكن من التراجع عن هذا",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "إلغاء",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "!نعم ، احذفها",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show({
              loader: this.loader,
            });

            MunicipalsOrdersService.deleteOrder(id)
              .then((resp) => {
                let i = this.vaccination_center_orders_data
                  .map((item) => item.id)
                  .indexOf(id); // find index of your object
                this.vaccination_center_orders_data.splice(i, 1);
                loader.hide();
                this.$swal.fire({
                  text: resp.data.message,
                  icon: "success",
                  confirmButtonText: "حسنا",
                });
              })
              .catch((err) => {
                loader.hide();

                this.$swal.fire({
                  icon: "error",
                  title: "...عذرا",
                  text: err.response.data.errors.name,
                });
              });
          }
        });
    },

    getMuncipalOrder() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MunicipalsOrdersService.getRequestForMuncipal(
        this.page_number,
        this.page_size,
        this.order_state,
        this.$route.params.id
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            this.vaccination_center_orders_data = res.data.list;
            this.total = res.data.total;

          }, 10);
        })
        .catch(() => {
          loader.hide();


          this.vaccination_center_orders_data = {};
          this.total = 0;


          // this.$swal.fire({
          //   icon: "error",
          //   title: "...عذرا",
          //   text: err.response.data.message,
          // });
        });
    },

    openOrderRejectModel(order_id) {
      this.rejectOrder.id = order_id;
      this.order_reject_model = true;
    },

    OrderReject() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data = {
        id: this.rejectOrder.id,
        order_state: this.rejectOrder.order_state,
        reason: this.rejectOrder.reason,
      };

      MunicipalsOrdersService.OrderReject(data)
        .then((resp) => {
          this.order_reject_model = false;
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });

            this.rejectOrder.id = "";
            this.rejectOrder.reason = "";

            this.getMuncipalOrder();
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.rejectOrder.id = "";
          this.rejectOrder.reason = "";

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    orderApproval(id) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MunicipalsOrdersService.orderApproval(id)

        .then((resp) => {
          this.getMuncipalOrder();
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.errors,
          });
        });
    },

    toggle_filter() {
      this.filter_box = !this.filter_box;
    },

    handleDropdown(state) {
      this.order_state = state.id
      this.getMuncipalOrder();
    },

    municipalityReceivedOrder(id) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MunicipalsOrdersService.municipalityReceivedOrder(id).then(response => {
        this.vaccination_center_orders_data = this.vaccination_center_orders_data.filter(item => item.id !== id)
        setTimeout(() => {
          loader.hide();
          this.$swal.fire({
            icon: 'success',
            text: response.data.message,
          })
          this.getMuncipalOrder()
        }, 10)
      })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors
          this.$swal.fire({
            icon: 'error',
            title: '...عذرا',
            text: err.response.data.message,
          })
        });
    }
  },
};
</script>

<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
