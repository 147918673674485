import * as API from "./API";

import store from "../store";

export default {


    getRequestForMuncipal(page_number, page_size, order_state, id) {
        return API.apiClient.get(`/api/Vaccination_Administration_municipality_requests/get_all/${id}?page_number=${page_number}&page_size=${page_size}&order_state=${order_state}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    requestVaccineForMunicipality(order) {
        return API.apiClient.post(`/api/Vaccination_Administration_municipality_requests/Add`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    // my code 
    requestVaccineForMunicipalityNedal(obj) {
        console.log(obj);
        return API.apiClient.post(`/api/Vaccination_Administration_municipality_requests/Add`, {
            municipal_id:  obj.municipal_id,
            vaccine_id:  obj.vaccine_id,
            order_state:  obj.order_state,
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },








    // getMuncipalOrderWithoutPagination(is_approved, id) {
    //     return API.apiClient.get(`/api/Order/get_orders_for_health_center?is_approved=${is_approved}&id=${id}`);
    // },

    getMuncipalOrder(page_number, page_size, id) {
        return API.apiClient.get(`/api/Health_center_municipality_requests/get_all_requests_for_municipal?page_number=${page_number}&page_size=${page_size}&municipal_id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },







    createHealthCenterOrder(order) {
        return API.apiClient.post(`/api/Health_center_municipality_requests/Add`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteOrder(id) {
        return API.apiClient.delete(`/api/Health_center_municipality_requests/block?id=${id}&state=3`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    orderApproval(id) {
        return API.apiClient.put(`/api/Health_center_municipality_requests/change_order_state?id=${id}&state=2`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    OrderReject(order) {
        return API.apiClient.put(`/api/Health_center_municipality_requests/Reject_Order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    quantification_order(order) {
        return API.apiClient.put(`/api/Health_center_municipality_requests/quantification_order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    update_quantification_order(order) {
        return API.apiClient.put(`/api/Health_center_municipality_requests/Update_quantification_order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMunicipalStorageApproved(page_number, page_size, id) {
        return API.apiClient.get(`/api/Health_center_municipality_requests/Get_all_requests_by_order_state?page_number=${page_number}&page_size=${page_size}&municipal_id=${id}&order_state=3`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    order_delivery_to_vaccination_center(order) {
        return API.apiClient.put(`/api/Health_center_municipality_requests/confirm_receipt_of_quantity`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMonthlyVaccinationMovement(id, vaccine_id) {
        return API.apiClient.get(`/api/Order/monthly_vaccination_movement?id=${id}&vaccine_id=${vaccine_id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },






    // getMuncipalOrderById(id) {
    //     return API.apiClient.get(`/api/Order/Order?id=${id}`);
    // },

    createMuncipalOrder(order) {
        return API.apiClient.post(`/api/Order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },








    getOrderReadyToDelivery(page_number, page_size, id) {
        return API.apiClient.get(`/api/Order/all_orders_Delevery_from_Medical_supplies?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    confirmReceipt(id) {
        return API.apiClient.get(`/api/Order/The_municipality_received?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    // My code **************************************** >

    municipalityReceivedOrder(id) {
        return API.apiClient.put(`/api/Vaccination_Administration_municipality_requests/received/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
            }
        });
    },

    // Requests Between Health Centers And Their Municipality

    getHealthCenterRequests(page_number, page_size, order_state, id) {
        return API.apiClient.get(`/api/Health_center_municipality_requestsNew/get_all_request/${id}?page_number=${page_number}&page_size=${page_size}&order_state=${order_state}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
            }
        });
    },

    approveOrder(id) {
        return API.apiClient.put(`/api/Health_center_municipality_requestsNew/change_order_state/${id}?order_state=2`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${store.state.auth.accessToken}`
                }
            });
    },

    rejectOrder(id, reson) {
        return API.apiClient.put(`/api/Health_center_municipality_requestsNew/rejection/${id}`, {
            reson: reson
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
            }
        });
    },

    cheekMunicipalStorages(vaccineId) {
        const id = store.state.auth.role.guid
        return API.apiClient.get(`/api/Health_center_municipality_requestsNew/Cheek_municipal_storage/${id}`,
            { params: { vaccine_id: vaccineId } },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${store.state.auth.accessToken}`
                }
            })
    },

    blockQuantityFromMunicipalStorage(body, id) {
        return API.apiClient.post(`/api/Health_center_municipality_requestsNew/Block_quantity_from_municipal_storage/${id}`, body, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    acknowledgDelivery(id) {
        return API.apiClient.put(`/api/Health_center_municipality_requestsNew/change_order_state/${id}?order_state=4`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${store.state.auth.accessToken}`
                }
            });
    },

};