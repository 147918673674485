<template>
  <div class="">
    <div class="">
      <h1 class="text-2xl font-semibold text-slate-900 dark:text-white">
        لوحة التحكم
      </h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div class="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-3">
        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white dark:bg-slate-700 dark:text-gray-200 text-gray-700 shadow-md">
          <div class="p-4 text-right">
            <p
              class="block antialiased text-base leading-normal font-normal text-blue-gray-600"
            >
              عدد المسجلين بالمركز
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900 dark:text-white"
            >
              {{ dashboard_data.total_of_citizen }}
            </h4>
          </div>

          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute left-0 -mt-4 grid h-16 w-16 place-items-center">
            <svg
              class="w-6 h-6 text-white fill-current"
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 50 50"
              viewBox="0 0 50 50"
            >
              <path d="M24.3141,5.9217c-1.1045,0-2,0.8955-2,2v1.0835c0,3.0571-2.331,5.5801-5.3088,5.8854h-6.6319c-2.9783-0.3052-5.3093-2.8283-5.3093-5.8854V7.9217c0-2.6419-4-2.6449-4,0v1.0835c0,4.4979,3.0131,8.3001,7.1251,9.5096v11.2928c-0.0042,0.0524-0.0157,0.1027-0.0157,0.1561v16c0,1.1045,0.8955,2,2,2s2-0.8955,2-2V30.9219h2.9585v15.0419c0,1.1045,0.8955,2,2,2s2-0.8955,2-2V30.9219h0.0573v-12.407c4.1119-1.2095,7.1249-5.0116,7.1249-9.5096V7.9217C26.3141,6.8173,25.4186,5.9217,24.3141,5.9217z" />
              <path d="M13.6893 12.9219c2.2091 0 4-1.7909 4-4 0-2.2092-1.7909-4-4-4C8.4061 4.9219 8.3991 12.9219 13.6893 12.9219zM6.06 8.736v.27M4 17.366H2.26v-.5C1.8611 16.8992 1.3929 16.8715 1 16.736v4.72h6.19v-2.22C6.01 18.796 4.94 18.166 4 17.366zM47.52 17.366v-1.33h-1.18v-3.24H45.1v3.24h-1.17v-1.97h-2.51v1.97H40.3v-9.69h-.62v-2.81h-.96v-1.5h-2.7v1.5h-.96v2.81h-.61v11.02h-.92v-7.93H27.29c-.06 1.73-.54 3.39-1.37 4.85.22.16.36.43.36.75 0 .52-.4.94-.89.94-.18 0-.35-.05-.49-.15-1.22 1.51-2.83 2.71-4.71 3.41v2.22H49v-4.09H47.52zM28.2 15.976c-.51 0-.92-.42-.92-.94 0-.53.41-.94.92-.94.49 0 .89.41.89.94C29.09 15.556 28.69 15.976 28.2 15.976zM28.2 12.956c-.51 0-.92-.42-.92-.93 0-.54.41-.96.92-.96.49 0 .89.42.89.96C29.09 12.536 28.69 12.956 28.2 12.956zM30.98 15.976c-.5 0-.89-.42-.89-.94 0-.53.39-.94.89-.94s.92.41.92.94C31.9 15.556 31.48 15.976 30.98 15.976zM30.98 12.956c-.5 0-.89-.42-.89-.93 0-.54.39-.96.89-.96s.92.42.92.96C31.9 12.536 31.48 12.956 30.98 12.956zM36.34 16.576h-1.06v-8.12h1.06V16.576zM37.89 16.576h-1.03v-8.12h1.03V16.576zM39.46 16.576h-1.05v-8.12h1.05V16.576z" />
              <rect
                width="2.259"
                height="3.54"
                x="30.459"
                y="4.81"
              />
              <path d="M1.355,16.756" />
            </svg>
          </div>
        </div>

        <!-- <div class="relative flex flex-col bg-clip-border rounded-xl bg-white dark:bg-slate-700 dark:text-gray-200 text-gray-700 shadow-md">
          
          <div class="p-4 text-right">
            <p
              class="block antialiased text-base leading-normal font-normal text-blue-gray-600"
            >
              اللقاحات
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900 dark:text-white"
            >
            {{ dashboard_data.total_of_vaccines }}
            </h4>
          </div>

          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute left-0 -mt-4 grid h-16 w-16 place-items-center">
            <svg class="mw-6 h-6 text-white stroke-current stroke-2"
                 xmlns="http://www.w3.org/2000/svg" data-name="Layer 3" viewBox="0 0 64 64">
                <path d="M51.707,1.293a1,1,0,0,0-1.414,0l-3,3a1,1,0,0,0,0,1.414L50.086,8.5,47,11.586,44.707,9.293a1,1,0,0,0-1.414,0l-29,29a1,1,0,0,0,0,1.414L16.086,41.5l-2.793,2.793a1,1,0,0,0,0,1.414L15.086,47.5,1.293,61.293l1.414,1.414L16.5,48.914l1.793,1.793a1,1,0,0,0,1.414,0L22.5,47.914l1.793,1.793a1,1,0,0,0,1.414,0L44.715,30.7A2.951,2.951,0,0,0,46,31v2H45a4,4,0,0,0-4,4V59a4,4,0,0,0,4,4H57a4,4,0,0,0,4-4V37a4,4,0,0,0-4-4H56V31a3,3,0,0,0,0-6V24a3,3,0,0,0-1.826-2.76l.533-.533a1,1,0,0,0,0-1.414L52.414,17,55.5,13.914l2.793,2.793a1,1,0,0,0,1.414,0l3-3a1,1,0,0,0,0-1.414ZM19,48.586,15.414,45,17.5,42.914,21.086,46.5Zm6-1L16.414,39l13-13H43.78A2.981,2.981,0,0,0,43,28a2.926,2.926,0,0,0,.081.666l-1.374-1.373-1.414,1.414L42.086,30.5,39,33.586l-3.293-3.293-1.414,1.414L37.586,35,34.5,38.086l-1.793-1.793-1.414,1.414L33.086,39.5,30,42.586l-3.293-3.293-1.414,1.414L28.586,44ZM59,55H48V40H59ZM55,35h2a2,2,0,0,1,2,2v1H47a1,1,0,0,0-1,1V56a1,1,0,0,0,1,1H59v2a2,2,0,0,1-2,2H45a2,2,0,0,1-2-2V37a2,2,0,0,1,2-2h2a1,1,0,0,0,1-1V31h6v3A1,1,0,0,0,55,35Zm2-7a1,1,0,0,1-1,1H46a1,1,0,0,1,0-2H56A1,1,0,0,1,57,28Zm-9-3V24a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v1Zm3.586-4H49a3,3,0,0,0-3,3H31.414L44,11.414,52.586,20ZM51,15.586,48.414,13,51.5,9.914,54.086,12.5Zm8-1L49.414,5,51,3.414,60.586,13Z"/>
            </svg>

          </div>
          
        </div>

        <div class="relative flex flex-col bg-clip-border rounded-xl bg-white dark:bg-slate-700 dark:text-gray-200 text-gray-700 shadow-md">
          
          <div class="p-4 text-right">
            <p
              class="block antialiased text-base leading-normal font-normal text-blue-gray-600"
            >
              المستخدمين
            </p>
            <h4
              class="block antialiased tracking-normal font-sans text-2xl font-semibold leading-snug text-blue-gray-900 dark:text-white"
            >
            {{ dashboard_data.total_of_user }}
            </h4>
          </div>

          <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute left-0 -mt-4 grid h-16 w-16 place-items-center">
            <svg class="w-6 h-6 text-white"               
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
            </svg>
          </div>
          
        </div> -->
      </div>

      <BarChart
        :labels="labels"
        :datasets="datasets"
      />
    </div>
  </div>
</template>

<script>
import Dashboard from "@/services/DashboardService";

import BarChart from "@/components/ChartJS/Bar.vue";

export default {
  components: {
    BarChart,
  },

  data() {
    return {
      

      dashboard_data: {},


      labels: [
       
      ],

      datasets: [
          {
              label: ' ',
              backgroundColor: '#f87979',
              data: []
          },
          
      ],

      most_consumeds_vaccine:[],

      vaccination_center_id : this.$store.state.auth.role.guid
      
    };
  },

  created() {
    this.getDashboard();
  },
  methods: {
    

    getDashboard() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      Dashboard.getDashboardForVaccinationCenters(this.vaccination_center_id)
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.dashboard_data = res.data;


            this.most_consumeds_vaccine = this.dashboard_data.most_consumeds_Vaccine

            for (let index = 0; index < this.most_consumeds_vaccine.length; index++) {
              const element = this.most_consumeds_vaccine[index];

              this.labels.push(element.name_en)  
              this.datasets[0].data.push(element.total_obtained_Vaccines)  
              
            }

          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style></style>
