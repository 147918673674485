import * as API from "./API";

import store from "../store";

export default {
    getAdministratorsWithoutPagination() {
        return API.apiClient.get(`/api/Administrator/GetAll`);
    },

    getAdministrators(page_number, page_size) {
        return API.apiClient.get(`/api/Administrator/GetAllUsers?page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getAdministratorsByRole(type) {
        return API.apiClient.get(`/api/Administrator/GetuserByFiltter?type=${type}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getAllAdministratorsByRole(type) {
        return API.apiClient.get(`/api/Administrator/get_all_user_by_filtter?type=${type}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    createAdministrator(administrator) {
        return API.apiClient.post(`/api/Administrator/Add`, administrator, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteAdministrator(id) {
        return API.apiClient.delete(`/api/Administrator/Delete?UserId=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    resetPassword(data) {
        return API.apiClient.post(`/api/Administrator/rest_password`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getRoles() {
        return API.apiClient.get(`/api/Administrator/GetRoles`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getPermissionById(id) {
        return API.apiClient.get(`/api/Administrator/GetpermissionsById?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getPermission() {
        return API.apiClient.get(`/api/Administrator/GetAllpermission`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getAdministrator(id) {
        return API.apiClient.get(`/api/Administrator/GetUserById?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateAdministrator(administrator) {
        return API.apiClient.put(`/api/Administrator/Update`, administrator, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

};