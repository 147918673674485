<template>
  <div class="mt-16 xl:mt-0 py-12  max-w-7xl mx-auto">
    <div class="">
      <h1
        class="text-4xl text-center font-semibold text-slate-900 dark:text-white"
      >
        منصة تحصين
      </h1>

    
    </div>

    <div class="mt-4 w-full relative">
      <div class="h-8 w-full flex items-center">
        <button
          @click="toggle_filter()"
          class="flex items-center hover:text-blue-600 cursor-pointer w-full"
        >
          <svg
            class="h-6 w-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
          >
            <path
              d="M2 7h.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2H9.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2zm4-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm24 11h-.142a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h20.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-4 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm4 7H19.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h10.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-14 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"
            />
          </svg>
          <span class="mr-2"> فرز </span>
        </button>

        <button
          v-if="in_search"
          @click="cancel_filter()"
          class="flex items-center justify-center w-28 md:w-48 text-center py-2 px-6 border border-transparent text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
        >
          <span class="ml-2"> إلغاء الفرز </span>

          <svg
            class="h-6 w-6 stroke-current"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.6974 6.69598L6.6974 18.696"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.6974 6.69598L18.6974 18.696"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>

      <!-- 
            <div class="hidden xl:flex xl:ml-0 h-8">
                <label for="search-field" class="sr-only">Search</label>
                <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                       
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path
                                fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                    <input
                        id="search-field"
                        class="block h-full bg-gray-50 dark:bg-slate-900 w-full border-transparent py-2 pr-8 dark:text-gray-400 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="بحث...."
                        type="search"
                        name="search"
                        v-model="filter_search"
                    />
                </div>
            </div>
             -->

      <transition
        enter-active-class="transition ease-in-out duration-200"
        enter-class="transform opacity-0 scale-y-0"
        enter-to-class="transform opacity-100 scale-y-100"
        leave-active-class="transition ease-in-out duration-75"
        leave-class="transform opacity-100 scale-y-100"
        leave-to-class="transform opacity-0 scale-y-0"
      >
        <div
          v-if="filter_box"
          class="absolute z-40 w-full top-8 sm:rounded-b-lg border-t-2 border-gray-700 dark:border-slate-400 bg-gray-300 dark:bg-slate-700 dark:bg-opacity-90 bg-opacity-90 px-4 pt-4 pb-8"
        >
          <div class="grid grid-cols-4 gap-8">
            <div class="input">
              <label
                for="filter_citizen_number"
                class="block text-sm font-medium"
              >
                رقم المنظومة
              </label>
              <input
                type="tel"
                id="filter_citizen_number"
                placeholder="أكتب رقم المنظومة."
                v-model="filter_citizen_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="citizen_filter_first_name"
                class="block text-sm font-medium"
              >
                الاسم الاول
              </label>
              <input
                type="text"
                id="citizen_filter_first_name"
                placeholder="أكتب الاسم الاول."
                v-model="filter_first_name"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="citizen_filter_middle_name"
                class="block text-sm font-medium"
              >
                اسم الاب
              </label>
              <input
                type="text"
                id="citizen_filter_middle_name"
                placeholder="أكتب اسم الاب."
                v-model="filter_middle_name"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="citizen_filter_last_name"
                class="block text-sm font-medium"
              >
                اللقب
              </label>
              <input
                type="text"
                id="citizen_filter_last_name"
                placeholder="أكتب اللقب."
                v-model="filter_last_name"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="citizen_filter_mother_name"
                class="block text-sm font-medium"
              >
                اسم الام
              </label>
              <input
                type="text"
                id="citizen_filter_mother_name"
                placeholder="أكتب اسم الام."
                v-model="filter_mother_name"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="filter_passport_number"
                class="block text-sm font-medium"
              >
                رقم جواز السفر
              </label>
              <input
                type="text"
                id="filter_passport_number"
                placeholder="رقم جواز السفر."
                v-model="filter_passport_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label for="nationalityid" class="block text-sm font-medium">
                اختر الجنسية
              </label>
              <select
                id="nationalityid"
                name="nationalityid"
                v-model="nationalityid"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option disabled selected>الجنسيات</option>
                <option
                  v-for="nationality in nationalities_data"
                  :key="nationality.id"
                  v-bind:value="nationality.id"
                >
                  {{ nationality.name_ar }}
                </option>
              </select>
            </div>

            <div
              v-if="nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'"
              class="input"
            >
              <label
                for="citizen_filter_national_number"
                class="block text-sm font-medium"
              >
                الرقم الوطني
              </label>
              <input
                type="number"
                id="citizen_filter_national_number"
                placeholder="أكتب الرقم الوطني."
                v-model="filter_national_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div
              v-if="nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'"
              class="input"
            >
              <label
                for="filter_family_paper_number"
                class="block text-sm font-medium"
              >
                رقم ورقة العائلة
              </label>
              <input
                type="text"
                id="filter_family_paper_number"
                placeholder="أكتب رقم ورقة العائلة."
                v-model="filter_family_paper_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div
              v-if="nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'"
              class="input"
            >
              <label
                for="filter_registration_number"
                class="block text-sm font-medium"
              >
                رقم القيد
              </label>
              <input
                type="text"
                id="filter_registration_number"
                placeholder="أكتب الاسم."
                v-model="filter_registration_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div
              v-if="nationalityid == '2378e80b-3358-427e-8544-9f776ea7e302'"
              class="input"
            >
              <label
                for="filter_brochure_number"
                class="block text-sm font-medium"
              >
                رقم الكتيب
              </label>
              <input
                type="text"
                id="filter_brochure_number"
                placeholder="رقم الكتيب."
                v-model="filter_brochure_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div class="input">
              <label
                for="filter_phone_number"
                class="block text-sm font-medium"
              >
                رقم الهاتف
              </label>
              <input
                type="tel"
                id="filter_phone_number"
                placeholder="أكتب رقم الهاتف."
                v-model="filter_phone_number"
                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
            <div class="inline w-full md:w-auto cursor-pointer">
              <button
                @click="do_search()"
                type="button"
                class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                بحث
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <div
            class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
          >
           
            <div scope="col" class="w-3/12 px-2 py-3">الاسم</div>
            <div scope="col" class="w-2/12 px-2 py-3 hidden md:block">
              رقم المنظومة
            </div>

            <div scope="col" class="w-1/12 px-2 py-3 hidden md:block">
              تاريخ الميلاد
            </div>

            <div scope="col" class="w-2/12 px-2 py-3 hidden md:block">
              البلدية
            </div>

            <div scope="col" class="w-3/12 px-2 py-3 hidden md:block">
              مركز التطعيم 
            </div>

            <div scope="col" class="w-1/12 px-2 py-3">الإجراءات</div>
          </div>

          <div
            v-if="total == 0"
            class="h-40 w-full bg-gray-200 dark:bg-gray-700 flex justify-center items-center"
          >
            <span v-if="in_search">
              لاتوجد نتائج متوافقة معا اختيارات الفرز.
            </span>

            <span v-else> لايوجد مسجلين </span>
          </div>

          <div
            v-else
            v-for="(citizen, personIdx) in citizens_data"
            :key="citizen.id"
            class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            :class="
              personIdx % 2 === 0
                ? 'bg-white dark:bg-slate-800'
                : 'bg-gray-50 dark:bg-slate-800'
            "
          >
            
            <div
              class="w-3/12 px-2 py-4 whitespace-nowrap text-sm truncate"
            >
              {{ citizen.first_name }} &nbsp; {{ citizen.middle_name }} &nbsp;
              {{ citizen.grandfather_name }} &nbsp; {{ citizen.last_name }}
            </div>
            <div
              class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ citizen.citizen_number }}
            </div>

            <div
              class="md:w-1/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ citizen.day }} / {{ citizen.month }} /
              {{ citizen.year }}
            </div>

            <div
              class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ citizen.muncipal_name }} 
            </div>

            <div
              class="md:w-3/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block"
            >
              {{ citizen.helth_Center_name }}
            </div>


            <div class="w-1/12 px-2 py-4">
              <div class="w-full flex items-center justify-between">
                

                <router-link
                  title="البطاقة"
                  :to="{
                    name: 'printCitizensCertificates',
                    params: { id: citizen.id },
                  }"
                  class="mx-2 px-1"
                  target="_blank"
                >
                  <svg
                    class="h-8 w-8 stroke-current hover:text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 256 256"
                  >
                    <rect width="256" height="256" fill="none" />
                    <line
                      x1="152"
                      x2="192"
                      y1="112"
                      y2="112"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="8"
                    />
                    <line
                      x1="152"
                      x2="192"
                      y1="144"
                      y2="144"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="8"
                    />
                    <circle
                      cx="92.1"
                      cy="120"
                      r="24"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="8"
                    />
                    <path
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="8"
                      d="M61.10869,167.99952a32.01032,32.01032,0,0,1,61.98292-.00215"
                    />
                    <rect
                      width="192"
                      height="160"
                      x="32"
                      y="48"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="8"
                      rx="8"
                    />
                  </svg>
                </router-link>

            

            

              </div>
            </div>
          </div>
        </table>
      </div>
    </div>

    <div
      class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"
    >
      <pagination
        dir="rtl"
        v-model="page_number"
        :per-page="page_size"
        :records="total"
        @paginate="getCitizens"
        class="z-10"
      />
    </div>
  </div>
</template>

<script>
import CitizensService from "@/services/CitizensService";
import NationalitiesService from "@/services/NationalitiesService";

export default {
  computed: {},

  data() {
    return {
      nationalities_data: {},
      citizens_data: {},

      filter_box: false,

      filter_administrator_id: "",

      // vaccination_center_id: '4d1ef014-af98-4338-a737-8ef699e7977b',

      nationalityid: "",

      filter_national_number: "",
      filter_search: "",
      filter_mother_name: "",
      filter_family_paper_number: "",
      filter_registration_number: "",
      filter_brochure_number: "",
      filter_passport_number: "",
      filter_phone_number: "",
      filter_citizen_number: "",
      filter_first_name: "",
      filter_middle_name: "",
      filter_last_name: "",

      page_number: 1,
      page_size: 10,
      total: 0,

      in_search: false,
    };
  },

  created() {
    this.getCitizens();
    this.getNationalites();
  },

  watch: {
    // filter_search: function() {
    //     this.getCitizens()
    // },
    // filter_national_number: function() {
    //     this.getCitizens()
    // },
    //     filter_administrator_id: function() {
    //         if(this.filter_administrator_id == 0){
    //             this.filter_administrator_id = '';
    //         }
    //         this.getCitizens()
    //     },
  },

  methods: {
    cancel_filter() {
      this.in_search = false;

      this.filter_national_number = "";
      this.filter_first_name = "";
      this.filter_middle_name = "";
      this.filter_last_name = "";
      this.filter_mother_name = "";
      this.filter_family_paper_number = "";
      this.filter_registration_number = "";
      this.filter_brochure_number = "";
      this.filter_passport_number = "";
      this.filter_phone_number = "";

      this.page_number = 1;
      this.page_size = 10;
      this.filter_citizen_number = "";
      this.nationalityid = "";

      this.getCitizens();
    },

    do_search() {
      this.page_number = 1;
      this.getCitizens();
      this.filter_box = !this.filter_box;
      this.in_search = true;
    },

    toggle_filter() {
      this.filter_box = !this.filter_box;
    },

    getCitizens() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      // console.log(this.$route.params.vaccination_center_id)

      CitizensService.getCitizensForSearch(
        this.filter_national_number,
        this.filter_first_name,
        this.filter_middle_name,
        this.filter_last_name,
        this.filter_mother_name,
        this.filter_family_paper_number,
        this.filter_registration_number,
        this.filter_brochure_number,
        this.filter_passport_number,
        this.filter_phone_number,
        this.page_number,
        this.page_size,
        this.filter_citizen_number,
        this.nationalityid,
      )

        // CitizensService.getCitizensByVaccinationCenter(

        //     this.page_number,
        //     this.page_size,
        //     this.vaccination_center_id,
        // )
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            this.citizens_data = res.data.list;
            this.total = res.data.total;
          }, 10);
        })
        .catch(() => {
          loader.hide();

          this.citizens_data = {};
          this.total = 0;

          // this.$swal.fire({
          //   icon: "error",
          //   title: "...عذرا",
          //   text: err.response.data.message,
          // });
        });
    },

    deleteCitizen(id) {
      this.$swal
        .fire({
          title: "هل أنت متأكد؟",
          text: "!لن تتمكن من التراجع عن هذا",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "إلغاء",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "!نعم ، احذفها",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show({
              loader: this.loader,
            });

            CitizensService.deleteCitizen(id)
              .then((resp) => {
                let i = this.citizens_data.map((item) => item.id).indexOf(id); // find index of your object
                this.citizens_data.splice(i, 1);
                loader.hide();
                this.$swal.fire({
                  text: resp.data.message,
                  icon: "success",
                  confirmButtonText: "حسنا",
                });
              })
              .catch((err) => {
                loader.hide();

                this.$swal.fire({
                  icon: "error",
                  title: "...عذرا",
                  text: err.response.data.errors.name,
                });
              });
          }
        });
    },

    getNationalites() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      NationalitiesService.getNationalitiesWithoutPagination()
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.nationalities_data = res.data;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
