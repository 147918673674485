<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        طلبات الإمداد الجاهزة للإستلام
      </h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <div
            class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
          >
            <div scope="col" class="w-7/12 md:w-4/12 px-4 py-3">اسم الصنف</div>

            <div scope="col" class="w-3/12 md:w-3/12 px-6 py-3 hidden md:block">
              Item name
            </div>

            <div scope="col" class="w-3/12 md:w-1/12 px-6 py-3 hidden md:block">
              الكمية
            </div>

            <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
              حالة الطلب
            </div>

            <div scope="col" class="w-3/12 md:w-1/12 px-2 py-3 hidden md:block">
              تاريخ الطلب
            </div>

            <div scope="col" class="w-5/12 md:w-1/12 px-6 py-3">الإجراءات</div>
          </div>

          <div
            v-if="orders_data.length == 0"
            class="h-40 w-full bg-gray-200 dark:bg-gray-700 flex justify-center items-center"
          >
            لا توجد طلبات جاهزة للإستلام .
          </div>

          <div
            v-for="(order, personIdx) in orders_data"
            v-else
            :key="order.id"
            class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            :class="
              personIdx % 2 === 0
                ? 'bg-white dark:bg-slate-800'
                : 'bg-gray-200 dark:bg-slate-800'
            "
          >
            <div class="w-7/12 md:w-4/12 px-4 py-4 text-sm">
              {{ order.vaccine_Name }}
            </div>

            <div class="w-3/12 md:w-3/12 px-6 py-4 text-sm hidden md:block">
              {{ order.vaccine_Name_En }}
            </div>

            <div class="md:w-1/12 px-6 py-4 text-sm truncate hidden md:block">
              {{ order.quantity }}
            </div>

            <div
              class="w-3/12 md:w-2/12 px-2 py-4 text-sm truncate hidden md:block"
            >
              <span
                class="font-bold py-1 px-2 text-white rounded-full"
                :class="order.order_state | municipal_order_state_style"
              >
                {{ order.order_state | municipal_order_state }}
              </span>
            </div>

            <div class="md:w-1/12 px-2 py-4 text-sm truncate hidden md:block">
              {{ order.insert_at }}
            </div>

            <div class="w-5/12 md:w-1/12 px-6 py-4">
              <div class="w-full flex items-center justify-between">
                <div class="w-12">
                  <button
                    v-if="order.order_state === 4"
                    title="إقرار بالإستلام"
                    class="cursor-pointer"
                    @click="municipalityReceivedOrder(order.id)"
                  >
                    <DeliverIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </table>
      </div>
    </div>

    <div
      class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"
    >
      <pagination
        v-model="page_number"
        dir="rtl"
        :per-page="page_size"
        :records="total"
        class="z-10"
        @paginate="getMuncipalOrder"
      />
    </div>
  </div>
</template>

<script>
import MunicipalsOrdersService from "@/services/MunicipalsOrdersService";
import DeliverIcon from "../../medicalSuppliesFolder/medicalSupplyOrders/components/icons/DeliverIcon.vue"

export default {
  components: {
    DeliverIcon,
  },

  data() {
    return {
      orders_data: {},
      page_number: 1,
      page_size: 10,
      total: 0,
      order_state: 4,

      municipal_id: this.$route.params.id,

      filter_box: false,

      filter_search: "",
      filter_is_approved: "",
    };
  },

  created() {
    this.getMuncipalOrder();
  },

  methods: {
    // confirmReceipt(id) {
    //   let loader = this.$loading.show({
    //     loader: this.loader,
    //   });

    //   MunicipalsOrdersService.confirmReceipt(id)
    //     .then((resp) => {
    //       this.getOrderReadyToDelivery();
    //       setTimeout(() => {
    //         loader.hide();
    //         this.$swal.fire({
    //           icon: "success",
    //           text: resp.data.message,
    //         });
    //       }, 10);
    //     })
    //     .catch((err) => {
    //       loader.hide();

    //       this.errors = err.response.data.errors;
    //       this.$swal.fire({
    //         icon: "error",
    //         title: "...عذرا",
    //         text: err.response.data.errors.name,
    //       });
    //     });
    // },

    toggle_filter() {
      this.filter_box = !this.filter_box;
    },

    // getOrderReadyToDelivery() {
    //   let loader = this.$loading.show({
    //     loader: this.loader,
    //   });

    //   MunicipalsOrdersService.getOrderReadyToDelivery(
    //     this.page_number,
    //     this.page_size,
    //     this.municipal_id
    //   )
    //     .then((res) => {
    //       setTimeout(() => {
    //         loader.hide();
    //         this.orders_data = res.data.list;
    //         this.total = res.data.total;
    //       }, 10);
    //     })
    //     .catch(() => {
    //       loader.hide();

    //       this.orders_data = {};
    //       this.total = 0;

    //       // this.$swal.fire({
    //       //     icon: "error",
    //       //     title: "...عذرا",
    //       //     text: err.response.data.message,
    //       // });
    //     });
    // },

    getMuncipalOrder() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MunicipalsOrdersService.getRequestForMuncipal(
        this.page_number,
        this.page_size,
        this.order_state,
        this.$route.params.id
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            this.orders_data = res.data.list;
            this.total = res.data.total;
          }, 10);
        })
        .catch(() => {
          loader.hide();
          this.orders_data = [];
          this.total = 0;

          // this.$swal.fire({
          //   icon: "error",
          //   title: "...عذرا",
          //   text: err.response.data.message,
          // });
        });
    },

    municipalityReceivedOrder(id) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MunicipalsOrdersService.municipalityReceivedOrder(id).then(response => {
        this.orders_data = this.orders_data.filter(item => item.id !== id)
        setTimeout(() => {
          loader.hide();
          this.$swal.fire({
            icon: 'success',
            text: response.data.message,
          })
        }, 10)
      })
        .catch((err) => {
          loader.hide();
          this.errors = err.response.data.errors
          this.$swal.fire({
            icon: 'error',
            title: '...عذرا',
            text: err.response.data.message,
          })
        });
    }
  },
};
</script>

<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
