<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        الطلبات 
      </h1>

      <router-link
        title="إضافة"
        :to="{ name: 'administration-add-municipalOrders' }"
        class="w-28 md:w-40 text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        إنشاء طلب للبلدية
      </router-link>
    </div>

    <OrderStateDropdown @handle-dropdown="handleDropdown" />

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <div
            class="w-full grid grid-cols-municipalOrdersTable bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
          >
            <div scope="col" class="px-4 py-3">اسم الصنف</div>

            <div scope="col" class="px-6 py-3 hidden md:block">البلدية</div>

            <div
              v-if="this.order_state !== 6"
              scope="col"
              class="px-6 py-3 hidden md:block"
            >
              الكمية
            </div>

            <div
              v-if="this.order_state == 6"
              scope="col"
              class="px-6 py-3 hidden md:block"
            >
              سبب الرفض
            </div>

            <div scope="col" class="px-6 py-3 hidden md:block">حالة الطلب</div>

            <div scope="col" class="px-2 py-3 hidden md:block">تاريخ الطلب</div>

            <div scope="col" class="px-6 py-3">الإجراءات</div>
          </div>

          <div
            v-if="total == 0"
            class="h-40 w-full bg-gray-200 dark:bg-gray-700 flex justify-center items-center"
          >
            لا توجد طلبات.
          </div>

          <div
            v-for="(order, personIdx) in vaccination_center_orders_data"
            v-else
            :key="order.id"
            class="hover:bg-gray-100 grid grid-cols-municipalOrdersTable hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            :class="
              personIdx % 2 === 0
                ? 'bg-white dark:bg-slate-800'
                : 'bg-gray-200 dark:bg-slate-800'
            "
          >
            <div class="px-4 py-4 text-sm">
              {{ order.vaccine_Name_En }}
            </div>

            <div class="px-6 py-4 text-sm hidden md:block">
              {{ order.municipal_Name }}
            </div>

            <div
              v-if="order_state !== 6"
              class="px-6 py-4 text-sm truncate hidden md:block"
            >
              {{ order.quantity }}
            </div>

            <div
              v-if="order_state == 6 "
              class="px-2 py-4 text-sm hidden md:block relative z-50"
            >
              <p v-if="order.reson" class="h-6 overflow-hidden tooltip">{{ order.reson }}</p>
              <p v-if="order.reson" class="invisible absolute right-0 w-32 z-50 bg-gray-700 text-white p-1 rounded">{{ order.reson }}</p>
            </div>

            <div class="px-2 py-4 text-sm truncate hidden md:block">
              <span
                class="font-bold py-1 px-2 text-white rounded-full"
                :class="order.order_state | municipal_order_state_style"
              >
                {{ order.order_state | municipal_order_state }}
              </span>
            </div>

            <div class="px-2 py-4 text-sm truncate hidden md:block">
              {{ order.insert_at }}
            </div>

            <div class="px-6 py-4">
              <div class="w-full flex justify-center items-center gap-2">
                <button
                  v-if="order.order_state === 1"
                  title="رفض الطلب"
                  class="cursor-pointer"
                  @click="openRejectModal(order)"
                >
                  <RejectIcon />
                </button>

                <button
                  v-if="order.order_state === 1"
                  title="قبول الطلب"
                  class="cursor-pointer"
                  @click="approveOrder(order.id)"
                >
                  <ApproveIcon />
                </button>

                <button
                  v-if="order.order_state === 2"
                  title="تجهيز اللقاح"
                  class="cursor-pointer"
                  @click="openModal(order)"
                >
                  <svg
                    class="w-6 h-6 fill-current hover:text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 64 64"
                    viewBox="0 0 64 64"
                  >
                    <path
                      d="M37.8 4.3c0-.6-.5-1-1-1h-5.9C30.1 1.3 28.2 0 26 0c-3 0-5.3 2.4-5.3 5.3h16.2C37.4 5.3 37.8 4.9 37.8 4.3zM30.1 10.3v-3h-8.4v2.2c0 2.2 1.8 4 4 4h1.2C28.7 13.5 30.1 12.1 30.1 10.3zM46.9 21.3c.2 0 .5.1.7.2l1.3 1.1V19H45v3.6l1.3-1.1C46.4 21.4 46.6 21.3 46.9 21.3zM24.3 15.4L24.2 17h2.4l.2-1.4h-1.2C25.2 15.6 24.8 15.5 24.3 15.4zM42.5 56.1c0 0-.1 0-.2 0l0 0h-.1l-3 .3c-.2 0-.4 0-.6 0-.4-.1-.8-.2-1.1-.5-.5-.4-.8-1-.9-1.6l-.5-5.5c-.1-.6-.2-1.1-.4-1.7-.1-.2-.2-.4-.3-.6L31.2 40l-1.7-2.7h-5.7c-.6 0-1-.5-1-1 0-.6.5-1 1-1h5.3v-6h-1.2c-.4 0-.8-.3-.9-.6-.1-.1-.1-.3-.1-.4 0-.6.5-1 1-1h2.2c.1 0 .3 0 .4.1l.1.1c.1 0 .1.1.2.1l3 2.9c.5.5 1.2.8 1.9.8h6.8c.3 0 .5-.1.7-.3l0 0c.1 0 .1-.1.1-.2.1-.2.2-.4.2-.6 0-.3-.1-.5-.3-.7s-.4-.3-.7-.3h-5.3c-1.4 0-2.8-.6-3.8-1.5l-.1-.1c-.1-.1-.1-.1-.2-.2-.1-.1-.2-.2-.3-.3L32.7 27c-.1-.2-.2-.3-.3-.5l-.3-.6L29 20c0 0 0-.1-.1-.1-.1-.1-.2-.2-.3-.3-.1-.1-.1-.1-.2-.2-.1 0-.1-.1-.2-.1-.1-.1-.2-.1-.3-.1-.1 0-.1-.1-.2-.1S27.5 19 27.4 19c-.1 0-.2 0-.3 0H27h-2.8c-.3 0-.5.1-.8.1-.2.1-.4.2-.5.3 0 0 0 0-.1.1-.2.1-.3.2-.4.4l0 0c-.1.1-.2.3-.3.4 0 .1-.1.1-.1.2-.1.1-.1.3-.1.4V21l-2 18.2L19 47.7c0 .4-.1.7-.2 1.1-.4 1.5-1.1 2.9-2.2 4.1l-2.5 2.5c-.1.1-.2.3-.2.5s.1.5.2.7l5.2 5.2c.2.2.6.3.9.2.1 0 .2-.1.3-.2.1-.1.1-.2.2-.3.1-.2.1-.4 0-.6 0-.1-.1-.2-.1-.3l-1-1.4L19.4 59l0 0c-.7-1-.7-2.2-.1-3.3 0 0 0-.1.1-.1l1.9-2.7c.1-.1.1-.2.2-.3s.1-.2.2-.3c.1-.2.2-.3.2-.5 0-.1.1-.1.1-.2.2-.4.3-.9.4-1.4l1.6-8.9c0-.2.2-.4.4-.6.1-.1.3-.2.4-.2s.1 0 .2 0h.1c.1 0 .3.1.4.1.1.1.3.2.4.3l.7 1 5.7 8.4c.6.9 1 1.9 1.1 3 0 .1 0 .2 0 .3l.6 5.6c0 .1 0 .1.1.1 0 0 0 0 0 .1l.1.1c.1 0 .2.1.3 0l2.4-.9 5.6-2.1c.1 0 .1-.1.2-.1 0-.1.1-.1.1-.2 0-.2-.1-.2-.1-.3C42.6 56.1 42.6 56.1 42.5 56.1z"
                    />
                    <path
                      d="M55.2 19h-4.3v5.8c0 .4-.2.8-.6.9-.1.1-.3.1-.4.1-.2 0-.5-.1-.7-.2l-2.3-1.9-2.3 1.9c-.3.3-.7.3-1.1.1-.4-.2-.6-.5-.6-.9V19h-4.3c-1.1 0-2 .9-2 2v6c.2.1.5.1.7.1h5.3c.8 0 1.6.3 2.2.9s.9 1.3.9 2.2c0 .8-.3 1.6-.9 2.2-.1.1-.3.2-.4.3 0 0 0 0-.1.1S44.1 32.9 44 33c-.1 0-.1.1-.2.1-.1 0-.1 0-.2.1-.3.1-.6.2-1 .2h-5.8V35c.3.8 1 1.2 1.9 1.2h16.6c1.1 0 2-.9 2-2V21C57.2 19.9 56.3 19 55.2 19zM59.9 62H4.1c-.6 0-1 .5-1 1s.5 1 1 1H60c.6 0 1-.5 1-1S60.5 62 59.9 62z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </table>
      </div>
    </div>

    <div
      class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"
    >
      <pagination
        v-model="page_number"
        dir="rtl"
        :per-page="page_size"
        :records="total"
        class="z-10"
        @paginate="getRequestsformMunicipals"
      />
    </div>

    <RejectOrderModal
      :order="orderSent"
      :show-modal="showRejectModal"
      @cancel-reject-modal="closeRejectModal"
    />
    
    <VaccineDeliveryModal
      :order="orderSent"
      :show-modal="showModal"
      @cancel-modal="closeModal"
    />


    <div class="hidden print:block">
      <MedicalStoresFormVue />
    </div>
  </div>
</template>

<script>
import AdministrationOfVaccinationsOrdersService from "@/services/AdministrationOfVaccinationsOrdersService";
import VaccineDeliveryModal from "./components/VaccineDeliveryModal.vue";
import RejectOrderModal from "./components/RejectOrderModal.vue";
import OrderStateDropdown from "./components/OrderStateDropdown.vue";
import { STATUS } from "./models/status";
import ApproveIcon from "./components/icons/ApproveIcon.vue";
import RejectIcon from "./components/icons/RejectIcon.vue";
import MedicalSuppliesOrderService from "@/services/MedicalSuppliesOrdersService";
import MedicalStoresFormVue from "./components/MedicalStoresForm.vue";

export default {
  components: {
    VaccineDeliveryModal,
    RejectOrderModal,
    OrderStateDropdown,
    ApproveIcon,
    RejectIcon,
    MedicalStoresFormVue,
  },

  data() {
    return {
      vaccination_center_orders_data: {},
      page_number: 1,
      page_size: 10,
      order_state: STATUS.ALL,
      total: 0,

      showModal: false,
      showRejectModal: false,
      orderSent: {},
    };
  },

  computed: {},

  created() {
    this.getRequestsformMunicipals();
  },
  methods: {
    getRequestsformMunicipals() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      AdministrationOfVaccinationsOrdersService.getRequestsformMunicipals(
        this.page_number,
        this.page_size,
        this.order_state
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            this.vaccination_center_orders_data = res.data.list;
            this.total = res.data.total;
          }, 10);
        })
        .catch(() => {
          loader.hide();

          this.vaccination_center_orders_data = {};
          this.total = 0;

          // this.$swal.fire({
          //   icon: "error",
          //   title: "...عذرا",
          //   text: err.response.data.message,
          // });
        });
    },

    confirmDelivery(order) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      AdministrationOfVaccinationsOrdersService.confirmDelivery(order)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.getcartForMedicalSupplier();
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    openModal(order) {
      this.showModal = true;
      this.orderSent = order;
    },

    openRejectModal(order) {
      this.showRejectModal = true;
      this.orderSent = order;
    },

    closeModal(id) {
      this.showModal = false;
      if (id) {
        this.getRequestsformMunicipals();
      }
    },

    closeRejectModal(id) {
      this.showRejectModal = false;
      if (id) {
        this.getRequestsformMunicipals();
      }
    },

    handleDropdown(state) {
      this.page_number = 1;
      this.order_state = state.id;
      this.getRequestsformMunicipals();
    },

    onPrint() {
      setTimeout(() => window.print(), 200);
    },

    approveOrder(id) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MedicalSuppliesOrderService.approveOrder(id)
        .then((resp) => {
          this.vaccination_center_orders_data =
            this.vaccination_center_orders_data.filter(
              (item) => item.id !== id
            );
          setTimeout(() => {
            loader.hide();
            this.getRequestsformMunicipals();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style>

.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
