<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        تسليم الطلبات
      </h1>
    </div>

    <OrderStateDropdown @handle-dropdown="handleDropdown" />

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <div
        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <div
            class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right"
          >
            <div scope="col" class="w-7/12 md:w-4/12 px-4 py-3">اسم الصنف</div>

            <div scope="col" class="w-3/12 md:w-3/12 px-6 py-3 hidden md:block">
              البلدية
            </div>

            <div scope="col" class="w-3/12 md:w-1/12 px-6 py-3 hidden md:block">
              الكمية
            </div>

            <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
              حالة الطلب
            </div>

            <div scope="col" class="w-3/12 md:w-1/12 px-2 py-3 hidden md:block">
              تاريخ الطلب
            </div>

            <div scope="col" class="w-5/12 md:w-1/12 px-6 py-3">الإجراءات</div>
          </div>

          <div
            v-if="total == 0"
            class="h-40 w-full bg-gray-200 dark:bg-gray-700 flex justify-center items-center"
          >
            لا توجد طلبات.
          </div>

          <div
            v-for="(order, personIdx) in vaccination_center_orders_data"
            v-else
            :key="order.id"
            class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            :class="
              personIdx % 2 === 0
                ? 'bg-white dark:bg-slate-800'
                : 'bg-gray-200 dark:bg-slate-800'
            "
          >
            <div class="w-7/12 md:w-4/12 px-4 py-4 text-sm">
              {{ order.vaccine_Name_En }}
            </div>

            <div class="w-3/12 md:w-3/12 px-6 py-4 text-sm hidden md:block">
              {{ order.municipal_Name }}
            </div>

            <div class="md:w-1/12 px-6 py-4 text-sm truncate hidden md:block">
              {{ order.quantity }}
            </div>

            <div
              class="w-3/12 md:w-2/12 px-2 py-4 text-sm truncate hidden md:block"
            >
              <span
                class="font-bold py-1 px-2 text-white rounded-full"
                :class="order.order_state | municipal_order_state_style"
              >
                {{ order.order_state | municipal_order_state }}
              </span>
            </div>

            <div class="md:w-1/12 px-2 py-4 text-sm truncate hidden md:block">
              {{ order.insert_at }}
            </div>

            <div class="w-5/12 md:w-1/12 px-6 py-4">
              <div class="w-full flex justify-center items-center gap-2">
                <button
                  v-if="order.order_state === 3"
                  title="إقرار بالتسليم"
                  class="cursor-pointer"
                  @click="acknowledgDelivery(order.id)"
                >
                  <DeliverIcon />
                </button>

                <router-link
                  v-if="order.order_state === 4"
                  target="_blank"
                  title="البطاقة"
                  :to="{
                    name: 'deliveryvaccinesMedicalSuppliesOrderPrint',
                    params: { id: order.id },
                  }"
                >
                  <OrderPrint />
                </router-link>
              </div>
            </div>
          </div>
        </table>
      </div>
    </div>

    <div
      class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative"
    >
      <pagination
        v-model="page_number"
        dir="rtl"
        :per-page="page_size"
        :records="total"
        class="z-10"
        @paginate="getRequestsformMunicipals"
      />
    </div>

    <div class="hidden print:block">
      <MedicalStoresFormVue />
    </div>
  </div>
</template>

<script>
import AdministrationOfVaccinationsOrdersService from "@/services/AdministrationOfVaccinationsOrdersService";
import OrderStateDropdown from "./components/OrderStateDropdown.vue";
import { STATUS } from "./models/status";
import DeliverIcon from "./components/icons/DeliverIcon.vue";
import OrderPrint from "./components/icons/OrderPrint.vue";
import MedicalSuppliesOrderService from "@/services/MedicalSuppliesOrdersService";
import MedicalStoresFormVue from "./components/MedicalStoresForm.vue";

export default {
  components: {
    OrderStateDropdown,
    DeliverIcon,
    OrderPrint,
    MedicalStoresFormVue,
  },

  data() {
    return {
      vaccination_center_orders_data: {},
      page_number: 1,
      page_size: 10,
      order_state: STATUS.ALL,
      total: 0,

      showModal: false,
      showRejectModal: false,
      orderSent: {},
    };
  },

  computed: {},

  created() {
    this.getRequestsformMunicipals();
  },
  methods: {
    getRequestsformMunicipals() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      AdministrationOfVaccinationsOrdersService.getRequestsformMunicipals(
        this.page_number,
        this.page_size,
        this.order_state
      )
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            this.vaccination_center_orders_data = res.data.list;
            this.total = res.data.total;
          }, 10);
        })
        .catch(() => {
          loader.hide();
          this.vaccination_center_orders_data = {};
          this.total = 0;

          // this.$swal.fire({
          //   icon: "error",
          //   title: "...عذرا",
          //   text: err.response.data.message,
          // });
        });
    },

    handleDropdown(state) {
      this.page_number = 1;
      this.order_state = state.id;
      this.getRequestsformMunicipals();
    },

    acknowledgDelivery(id) {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      MedicalSuppliesOrderService.acknowledgDelivery(id)
        .then((resp) => {
          this.vaccination_center_orders_data =
            this.vaccination_center_orders_data.filter(
              (item) => item.id !== id
            );

          loader.hide();
          setTimeout(() => {
            window.print();
          }, 80);
          setTimeout(() => {
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.getRequestsformMunicipals();
          }, 100);
        })
        .catch((err) => {
          loader.hide();
          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },
  },
};
</script>

<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
