<template>
  <div
    v-if="error !== null"
    class="bg-red-200 py-6 px-4 sm:p-6 shadow sm:rounded-md sm:overflow-hidden mb-8"
  >
    <p
      v-if="error && getType(error) === 'string'"
      key="error"
      class="text-red-900"
    >
      {{ error }}
    </p>

    <ul
      v-for="key in errorKeys"
      :key="key"
      class="text-red-900 list-disc list-inside"
    >
      <li
        v-for="(item, index) in getErrors(key)"
        :key="`${index}-error`"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    name: "ErrorMessage",
    props: {
        error: {
            type: [Object, String],
            default: null,
        },
    },
    computed: {
        errorKeys() {
            if (!this.error || this.getType(this.error) === "string") {
                return null;
            }
            return Object.keys(this.error);
        },
    },
    methods: {
        getErrors(key) {
            return this.error[key];
        },
        getType(obj) {
            return Object.prototype.toString
                .call(obj)
                .slice(8, -1)
                .toLowerCase();
        },
    },
};
</script>
