import * as API from "./API";

import store from "../store";

export default {


    getHealthCenterOrder(page_number, page_size, id, order_state) {
        return API.apiClient.get(`/api/Health_center_municipality_requestsNew/get_all/${id}?page_number=${page_number}&page_size=${page_size}&order_state=${order_state}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    createHealthCenterOrder(order) {
        return API.apiClient.post(`/api/Health_center_municipality_requestsNew/Add`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteOrder(id) {
        return API.apiClient.delete(`/api/Health_center_municipality_requests/block?id=${id}&state=3`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    get_order_ready_to_delivery(page_number, page_size, id) {
        return API.apiClient.get(`/api/Health_center_municipality_requests/Get_all_requests_by_order_state_for_health_center?page_number=${page_number}&page_size=${page_size}&id=${id}&order_state=4`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    confirm_receipt(id) {
        return API.apiClient.put(`/api/Health_center_municipality_requests/Confirmation_of_receipt_by_the_health_center?id=${id}&`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },














  /// ******************  My code nedal

    getHealthCenterOrderWithoutPagination(is_approved, id) {
        return API.apiClient.get(`/api/Order/get_orders_for_health_center?is_approved=${is_approved}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getHealthCenterOrderById(id) {
        return API.apiClient.get(`/api/Order/Order?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateHealthCenterOrder(order) {
        return API.apiClient.put(`/api/Order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    healthCenterReceivedOrder(id) {
        return API.apiClient.put(`/api/Health_center_municipality_requestsNew/received/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
            }
        });
    },

};