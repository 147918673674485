<template>
  <div class="mt-16 xl:mt-0">
    <div class="flex justify-between items-center">
      <h1
        class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white"
      >
        إضافة لقاح للمخزن
      </h1>
    </div>

    <div class="py-2 align-middle inline-block min-w-full mt-6">
      <errormessage :error="errors" />
      <form
        class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4 sm:p-6 shadow border dark:border-slate-800 sm:rounded-md sm:overflow-hidden"
        @submit.prevent="addVaccineToStorage"
      >
        <div class="grid md:grid-cols-5 gap-8">
          <div class="input">
            <label
              for="vaccine_id"
              class="block text-sm font-medium"
            >
              اختر القاح
            </label>
            <select
              id="vaccine_id"
              v-model="vaccine_to_storage.vaccine_id"
              name="vaccine_id"
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option
                disabled
                selected
              >
                القاح
              </option>
              <option
                v-for="vaccine in vaccines_data"
                :key="vaccine.id"
                :value="vaccine.id"
              >
                {{ vaccine.name_en }}
              </option>
            </select>
          </div>

          <div
            class="input"
            :class="{ invalid: $v.vaccine_to_storage.commercialName.$error }"
          >
            <label
              for="vaccine_to_storage_commercialName"
              class="block text-sm font-medium"
            >
              الإسم التجاري
            </label>
            <input
              id="vaccine_to_storage_commercialName"
              v-model="vaccine_to_storage.commercialName"
              type="text"
              placeholder="أكتب الإسم التجاري ."
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.vaccine_to_storage.commercialName.$touch()"
            >
          </div>

          <div
            class="input"
            :class="{ invalid: $v.vaccine_to_storage.batch_number.$error }"
          >
            <label
              for="vaccine_to_storage_batch_number"
              class="block text-sm font-medium"
            >
              رقم التشغيلة
            </label>
            <input
              id="vaccine_to_storage_batch_number"
              v-model="vaccine_to_storage.batch_number"
              type="text"
              placeholder="أكتب رقم التشغيلة."
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.vaccine_to_storage.batch_number.$touch()"
            >
          </div>

          <div
            class="input"
            :class="{ invalid: $v.vaccine_to_storage.quantity.$error }"
          >
            <label
              for="vaccine_to_storage_quantity"
              class="block text-sm font-medium"
            >
              الكمية
            </label>
            <input
              id="vaccine_to_storage_quantity"
              v-model="vaccine_to_storage.quantity"
              type="number"
              placeholder="أكتب الكمية."
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.vaccine_to_storage.quantity.$touch()"
              @keydown="restrictNegativeNumbers"
              @wheel="disableSpinner"
              @input="convertQuantityToPositive($event)"
            >
          </div>

          <div
            class="input"
            :class="{ invalid: $v.vaccine_to_storage.unitPrice.$error }"
          >
            <label
              for="vaccine_to_storage_unitPrice"
              class="block text-sm font-medium"
            >
              سعر الوحدة
            </label>
            <input
              id="vaccine_to_storage_unitPrice"
              v-model="vaccine_to_storage.unitPrice"
              type="number"
              step="0.0001"
              placeholder="أكتب سعر الوحدة."
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.vaccine_to_storage.unitPrice.$touch()"
              @keydown="restrictNegativeNumbers"
              @wheel="disableSpinner"
              @input="convertPriceToPositive($event)"
            >
          </div>

          <div
            class="input"
            :class="{ invalid: $v.vaccine_to_storage.production_date.$error }"
          >
            <label
              for="vaccine_to_storage_production_date"
              class="block text-sm font-medium"
            >
              تاريخ الانتاج
            </label>
            <input
              id="vaccine_to_storage_production_date"
              v-model="vaccine_to_storage.production_date"
              type="date"
              placeholder="أكتب تاريخ الانتاج."
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.vaccine_to_storage.production_date.$touch()"
            >
          </div>

          <div
            class="input"
            :class="{ invalid: $v.vaccine_to_storage.expired_date.$error }"
          >
            <label
              for="vaccine_to_storage_expired_date"
              class="block text-sm font-medium"
            >
              تاريخ الانتهاء
            </label>
            <input
              id="vaccine_to_storage_expired_date"
              v-model="vaccine_to_storage.expired_date"
              type="date"
              placeholder="أكتب تاريخ الانتهاء."
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.vaccine_to_storage.expired_date.$touch()"
            >
          </div>

          <div
            class="input"
            :class="{ invalid: $v.vaccine_to_storage.importingCompany.$error }"
          >
            <label
              for="vaccine_to_storage_importingCompany"
              class="block text-sm font-medium"
            >
              الشركة المستوردة
            </label>
            <input
              id="vaccine_to_storage_importingCompany"
              v-model="vaccine_to_storage.importingCompany"
              type="text"
              placeholder="أكتب الشركة المستوردة."
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.vaccine_to_storage.importingCompany.$touch()"
            >
          </div>

          <div
            class="input"
            :class="{ invalid: $v.vaccine_to_storage.notes.$error }"
          >
            <label
              for="vaccine_to_storage_notes"
              class="block text-sm font-medium"
            >
              ملاحظات
            </label>
            <textarea
              id="vaccine_to_storage_notes"
              v-model="vaccine_to_storage.notes"
              rows="5"
              type="text"
              placeholder="أكتب ملاحظات."
              class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              @blur="$v.vaccine_to_storage.notes.$touch()"
            />
          </div>
        </div>

        <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
          <div
            class="inline w-full md:w-auto"
            :class="$v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer'"
          >
            <button
              :disabled="$v.$invalid"
              type="submit"
              class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
              :class="
                $v.$invalid
                  ? 'bg-gray-600'
                  : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              "
            >
              إضافة
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import errormessage from "@/components/ErrorMessage";
import { required } from "vuelidate/lib/validators";
import MedicalSuppliesStorageService from "@/services/MedicalSuppliesStorageService";

import VaccinesService from "@/services/VaccinesService";

export default {
  components: {
    errormessage,
  },
  data() {
    return {
      errors: null,

      vaccines_data: {},
      vaccine_to_storage: {
        batch_number: "",
        quantity: "",
        production_date: "",
        expired_date: "",
        vaccine_id: "",
        medical_suppliesid: this.$route.params.id,
        commercialName: "",
        unitPrice: "",
        notes: "",
        importingCompany: "",
      },
    };
  },

  created() {
    this.getVaccines();
  },
  validations: {
    vaccine_to_storage: {
      batch_number: {
        required,
      },
      quantity: {
        required,
      },
      production_date: {
        required,
      },
      expired_date: {
        required,
      },
      vaccine_id: {
        required,
      },
      commercialName: {
        required,
      },
      unitPrice: {
        required,
      },
      notes: {
        required,
      },
      importingCompany: {
        required,
      },
    },
  },
  methods: {
    getVaccines() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      VaccinesService.getVaccinesWithoutPagination()
        .then((res) => {
          setTimeout(() => {
            loader.hide();

            this.vaccines_data = res.data;
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    addVaccineToStorage() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      let data = {
        vaccineid: this.vaccine_to_storage.vaccine_id,
        medical_suppliesid: this.vaccine_to_storage.medical_suppliesid,
        commercialName: this.vaccine_to_storage.commercialName,
        batch_number: this.vaccine_to_storage.batch_number,
        quantity: this.vaccine_to_storage.quantity,
        min_quantity: 10,
        unitPrice: Number(this.vaccine_to_storage.unitPrice),
        production_date: this.vaccine_to_storage.production_date,
        expired_date: this.vaccine_to_storage.expired_date,
        importingCompany: this.vaccine_to_storage.importingCompany,
        notes: this.vaccine_to_storage.notes,
      };

      MedicalSuppliesStorageService.createMedicalSupplyStorage(data)
        .then((resp) => {
          setTimeout(() => {
            loader.hide();
            this.$swal.fire({
              icon: "success",
              text: resp.data.message,
            });
            this.$router.push({
              name: "vaccinesMedicalsuppliesStorage",
              params: { id: this.$route.params.id },
            });
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.errors = err.response.data.errors;
          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.errors.name,
          });
        });
    },

    convertPriceToPositive(event) {
      this.vaccine_to_storage.unitPrice = Math.abs(event.target.value)
    },

    convertQuantityToPositive(event) {
      this.vaccine_to_storage.quantity = Math.abs(event.target.value)
    },

    restrictNegativeNumbers(event) {
      const keyCode = event.keyCode || event.which;
      const restrictedKeys = [69, 109, 189, 107];
      if (restrictedKeys.includes(keyCode)) {
        event.preventDefault();
      }
    },

    disableSpinner(event) {
      event.preventDefault();
    },

  },
};
</script>

<style scoped>
.input.invalid label {
  color: red;
}
.input.invalid input {
  border: 1px solid red;
  background-color: #ffc9aa;
}

[type="submit"],
button {
  cursor: inherit;
  color: inherit;
}
</style>
